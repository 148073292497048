import React, { useState, useEffect } from "react";
import JifImg from "../../../../assets/images/demo.jpg";
import axios from "axios";
import "./Lots.css";
import LotLoader from "../Common/LotLoader";
import LotModal from "./LotModal";
import { useLocation, useNavigate } from "react-router-dom";
import Magnifier from "react-magnifier";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faTh, faThLarge } from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.min.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment/moment";

const headers = {
  "ngrok-skip-browser-warning": "69420",
};

const MainDeivLots = ({
  isSidebarVisible,
  isSinglePageVisible,
  onSinglePageToggle,
  lotSidebarState,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [viewType, setViewType] = useState("list");
  const [lots, setLots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLot, setSelectedLot] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let filterQuery = "";
  const location = useLocation();

  const fetchData = async () => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      };

      const query = new URLSearchParams(location.search);
      const status = query.get("status");
      const hash_id = query.get("hash_id");
      const categoryHashId = query.get("category_hash_id");

      let response;
      if (categoryHashId) {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lots?category=${categoryHashId}`,
          { headers }
        );
      } else if (hash_id) {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lots?state=${hash_id}`,
          { headers }
        );
      } else if (query.has("auctionsnearme")) {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lots?nearmylocation=50`,
          { headers }
        );
      } else if (status === "CLOSING") {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lots?closing=true`,
          { headers }
        );
      } else {
        response = await axios.get(`${process.env.REACT_APP_API_URL}/lots`, {
          headers,
        });
      }
      setLots(response?.data?.data ?? []);
      // console.log(response.data.data, "lots");
    } catch (error) {
      console.error("There was an error fetching the lots data:", error);
      setLots([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [location.search]);

  const handleWatchlistToggle = async (e, item) => {
    e.stopPropagation();

    const isWatched =
      item?.watchlists?.length > 0 && item.watchlists[0].is_active === 1;

    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };

    try {
      let response;
      if (isWatched) {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/watchlist/remove/${item.hash_id}`,
          { headers }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/watchlist/add/${item.hash_id}`,
          { headers }
        );
      }

      // console.log(response.message, "API Response");

      if (response.data.status === "success") {
        toast.success(response.data.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
      fetchData();
    } catch (error) {
      console.error("Error updating watchlist:", error);
      toast.error("Error updating watchlist.");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (lotSidebarState?.selectedAuctionStatus) {
      filterQuery += `status=${lotSidebarState.selectedAuctionStatus}`;
    }
    if (lotSidebarState?.selectedLotStatus) {
      filterQuery += `${filterQuery ? "&" : ""}filter=${lotSidebarState.selectedLotStatus
        }`;
    }
    if (lotSidebarState?.selectedCategory) {
      filterQuery += `${filterQuery ? "&" : ""}category=${lotSidebarState.selectedCategory
        }`;
    }
    if (lotSidebarState?.selectedCountry) {
      filterQuery += `${filterQuery ? "&" : ""}country=${lotSidebarState.selectedCountry
        }`;
    }
    if (lotSidebarState?.selectedCountryState) {
      filterQuery += `${filterQuery ? "&" : ""}countryState=${lotSidebarState.selectedCountryState
        }`;
    }
    if (lotSidebarState?.shippingAvailable !== "") {
      filterQuery += `${filterQuery ? "&" : ""}shippingAvailable=${lotSidebarState.shippingAvailable
        }`;
    }
    if (filterQuery) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/lots?${filterQuery}`, {
          headers,
        })
        .then((response) => {
          setLots(response?.data?.data ?? []);
          // console.log(response.data, "lots")
        })
        .catch((error) => {
          console.error("There was an error fetching the lots data:", error);
          setLots([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setLots([]);
      setIsLoading(false);
    }
  }, [lotSidebarState]);

  const totalItems = lots.length;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleViewChange = (view) => setViewType(view);

  const handleItemClick = (item) => {
    navigate(`/lots/${item.hash_id}`);
  };

  const generatePaginationButtons = () => {
    let buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(i);
    }
    return buttons;
  };

  const paginatedItems = lots.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const openModal = (item) => {
    setSelectedLot(item);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLot(null);
  };

  return (
    <div>
      <ToastContainer />
      <div className="row my-2 flex items-center justify-end">
        <div className="flex items-center justify-end w-full ">
          <button
            className={`view-button me-2 ${viewType === "grid" ? "active" : ""
              }`}
            onClick={() => handleViewChange("grid")}>
            <FontAwesomeIcon icon={faList} />
          </button>
          <button
            className={`view-button me-2 ${viewType === "largeGrid" ? "active" : ""
              }`}
            onClick={() => handleViewChange("largeGrid")}>
            <FontAwesomeIcon icon={faThLarge} />
          </button>
          <button
            className={`view-button ${viewType === "list" ? "active" : ""}`}
            onClick={() => handleViewChange("list")}>
            <FontAwesomeIcon icon={faTh} />
          </button>
        </div>
      </div>
      {!isSinglePageVisible && (
        <div className="row d-flex ">
          <div className="col-md-2">
            <div className="form-group d-flex align-items-center flex-row">
              <label htmlFor="itemsPerPageDropdown" className="me-2">
                Show:
              </label>
              <select
                className="form-select"
                id="itemsPerPageDropdown"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>         
          <div className="col-md-10">
            <div className=" d-flex justify-content-end">
              {!isSinglePageVisible && (
                <p>
                  Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                  {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                  {totalItems.toLocaleString()} lots
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={`row ${viewType === "list" ? "" : "d-flex"} gap-y-3`}>
        {paginatedItems?.length === 0 ? (
          isLoading ? (
            <LotLoader />
          ) : (
            <div className="text-center">
              <p>No data found</p>
            </div>
          )
        ) : viewType === "largeGrid" ? (
          paginatedItems?.map((item) => (
            <div className="col-md-4 mb-3 h-full" key={item.id} onClick={() => handleItemClick(item)}>
              {/* VIEW2 */}
              <div className="item border rounded-xl p-3 h-full" nClick={() => handleItemClick(item)}
                style={{ cursor: "pointer" }}>
                <div className="flex flex-col items-center justify-between h-full gap-2">
                  <p className="m-0 font-semibold text-md text-center">
                    {item.title}
                  </p>
                  <div className="image-lots w-full h-full flex items-center justify-center min-h-[249px] min-w-[245px] max-w-[250px] max-h-[250px]">
                    <Magnifier className="w-full h-full object-contain" loading="lazy"
                      src={
                        item?.images[0]?.image_path
                          ? `${process.env.REACT_APP_BASE_IMAGE_URL
                          }/${item?.auction_images[0]?.image_path?.replace(/^\//, "")}`
                          : JifImg
                      }
                    />
                  </div>
                  <p className="font-semibold text-sm m-0">
                    {item?.description?.length > 30
                      ? item?.description?.substring(0, 30) + "..."
                      : item?.description}
                  </p>
                  <div className="flex flex-col items-center justify-center gap-2">
                    <div className="text-center">
                      Estimate{item?.estimate_from} - {item?.estimate_to}
                    </div>
                    {moment(item?.auction?.end_date).isBefore(moment()) ? (
                      <button className="btn btn-secondary w-full" disabled>
                        Closed
                      </button>
                    ) : (
                      <button className="btn btn-primary w-full" onClick={() => openModal(item)}>                    Bid{" "}
                        <span>
                          {item?.highest_bidder?.amount
                            ? item?.highest_bidder?.amount
                            : item?.estimate_from}
                        </span>
                      </button>
                    )}
                    <LotModal
                      isOpen={isModalOpen}
                      lot={selectedLot}
                      onClose={closeModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : viewType === "grid" ? (
          paginatedItems?.map((item) => (
            <div className="row">
              {/* VIEW1 */}
              <li className={`item-list border rounded-xl ${item.id % 2 === 0 ? "even" : "odd"
                } flex items-center justify-between`}
                key={item.id}
                style={{ justifyContent: " center" }}>
                <div className="item-content flex items-center justify-between w-full "
                  onClick={() => handleItemClick(item)} style={{ cursor: "pointer" }}>
                  <div className="image-lots w-full h-full flex items-center justify-center min-h-[249px] min-w-[245px] max-w-[250px] max-h-[250px]">
                    <Magnifier
                      className="w-full h-full object-contain "
                      loading="lazy"
                      src={
                        item?.images[0]?.image_path
                          ? `${process.env.REACT_APP_BASE_IMAGE_URL
                          }/${item?.auction_images[0]?.image_path?.replace(/^\//, "")}`
                          : JifImg
                      }
                    />
                  </div>
                  <div className="col-md-6 view-one-col-two-mobile" onClick={() => handleItemClick(item)}>
                    <div className="d-flex align-items-center">
                      <p className="font-semibold text-sm m-0 ">
                        {" "}
                        Total Bids : {item?.bids?.length || 0}
                      </p>
                      <p className="font-semibold text-sm m-0 mx-3">
                        {(() => {
                          const timeLeft =
                            new Date(item?.auction?.end_time) - new Date();
                          const daysLeft = Math.floor(
                            timeLeft / (1000 * 60 * 60 * 24)
                          );
                          const timeLeftDisplay =
                            daysLeft > 0 ? `${daysLeft} days left` : "Closed";
                          return timeLeftDisplay;
                        })()}
                      </p>
                      {item?.highest_bidder?.status === "Winning" && (
                        <p className="view-one-col-five-mobile mx-3">
                          High Bid :
                          {item?.highest_bidder?.amount} USD
                        </p>
                      )}
                    </div>
                    <p className="font-semibold">{item?.title}</p>
                  </div>
                  <div className="col-md-2 flex items-center justify-center flex-col view-one-col-six-mobile">
                    <div className="font-semibold text-sm text-center">
                      Estimate : {item?.estimate_from} - {item?.estimate_to}
                    </div>
                    {moment(item?.auction?.end_date).isBefore(moment()) ? (
                      <button className="btn btn-secondary w-full" disabled>
                        Closed
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary w-full"
                        onClick={() => openModal(item)}>
                        Bid{" "}
                        <span>
                          {item?.highest_bidder?.amount
                            ? item?.highest_bidder?.amount
                            : item?.estimate_from}
                        </span>
                      </button>
                    )}
                    <LotModal
                      isOpen={isModalOpen}
                      lot={selectedLot}
                      onClose={closeModal}
                    />                   
                  </div>
                </div>
              </li>
            </div>
          ))
        ) : (
          paginatedItems?.map((item) => (
            <div className={`col-md-${viewType === "list" ? "12" : viewType === "grid" ? "6" : "4"} mb-2`}
              key={item.id}>
              {/* VIEW 3*/}
              <div className={`cuckoo ${viewType} overflow-hidden border rounded-xl w-full h-full `}>
                <div className="clock">
                  <p className="schneider bg-gray-400 px-3 py-2 m-0 text-white font-semibold text-capitalize" onClick={() => handleItemClick(item)}>
                    <span>{item?.title}</span>
                  </p>
                </div>
                <div className="d-flex watch px-3 py-2 flex items-center justify-center view-three-mobile-box"
                  onClick={() => handleItemClick(item)} style={{ cursor: "pointer" }}>
                  <div className="col-md-3 flex flex-col items-center justify-between view-three-col-one-mobile">
                    <div className="jif ">
                      <div className="magnifier-container flex items-center justify-center">
                        <Magnifier className="w-full h-full object-contain " loading="lazy"
                          src={
                            item?.images[0]?.image_path
                              ? `${process.env.REACT_APP_BASE_IMAGE_URL
                              }/${item?.auction_images[0]?.image_path?.replace(
                                /^\//,
                                ""
                              )}`
                              : JifImg
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 cuckoo-clock1" onClick={() => handleItemClick(item)}>
                    <div className="text-start top">
                      <div className="d-flex align-items-center">
                        <p className="text-lg m-0">
                          {" "}
                          <span className="font-semibold"> Total Bids : </span>{item?.bids?.length || 0}
                        </p>
                        {item?.highest_bidder?.status === "Winning" && (
                          <p className="m-0 mx-3 text-lg">
                            <span className="font-semibold"> High Bid: </span>{item?.highest_bidder?.amount} USD
                          </p>
                        )}
                      </div>
                      <p className="m-0 text-base font-semibold view-three-des-mobile">
                        {item?.description?.length > 80
                          ? item?.description?.substring(0, 80) + "..."
                          : item?.description}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 cuckoo-clock1 flex flex-col items-center justify-center gap-2 border-l border-gray-400 h-full">
                    <p className="realtors text-sm text-center m-0">
                      <span className="font-semibold">City :- </span>{item?.auction?.state?.name}
                    </p>
                    <div className="text-center text-sm ">
                      <span className="font-semibold">Estimate </span>{item?.estimate_from} - {item?.estimate_to}
                    </div>
                    {moment(item?.auction?.end_date).isBefore(moment()) ? (
                      <button className="btn btn-secondary w-full" disabled>
                        Closed
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary w-full"
                        onClick={() => openModal(item)}>
                        Bid{" "}
                        <span>
                          {item?.highest_bidder?.amount
                            ? item?.highest_bidder?.amount
                            : item?.estimate_from}
                        </span>
                      </button>
                    )}
                    <LotModal
                      isOpen={isModalOpen}
                      lot={selectedLot}
                      onClose={closeModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {!isSinglePageVisible && (
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-end mb-3">
              <nav aria-label="Page navigation">
                <ul className="pagination">
                  {currentPage > 1 && (
                    <li className="page-item">
                      <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                        <i className="fas fa-chevron-left"></i>
                      </button>
                    </li>
                  )}
                  {generatePaginationButtons().map((button, index) => (
                    <li key={index} className={`page-item ${button === currentPage ? "active" : ""}`}>
                      {button === "..." ? (
                        <span className="page-link">...</span>
                      ) : (
                        <button className="page-link" onClick={() => handlePageChange(button)}>
                          {button}
                        </button>
                      )}
                    </li>
                  ))}
                  {currentPage < totalPages && (
                    <li className="page-item">
                      <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                        <i className="fas fa-chevron-right"></i>
                      </button>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainDeivLots;
