import React from "react";
import Slider from "./Slider";
import Banner from "./Banner";

const Search = () => {
  return (
    <div className="search bg-transparent">
      <div className="container-fluid">
        <div className="row ">
          <h1 className="online-auction text-center mt-5 font-black">
            Live & Online Auctions. Search, Join & Bid Now
          </h1>
        </div>
        <div className="row mt-2">
          {/* <Slider /> */}
          <Banner/>
        </div>
      </div>
    </div>
  );
};

export default Search;
