import React from "react";
import { Box, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Editor from "react-simple-wysiwyg";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";

function Addblog({ open, onClose, fetchBloglist }) {

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      image: "",
      meta_title: "",
      meta_description: "",
    },
    onSubmit: async (values) => {
      // console.log(values, "form values");
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found in localStorage.");
          return;
        }
        const apiHeaders = {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning": "69420",
        };

        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("description", values.description);
        if (values.image) {
          formData.append("image", values.image);
        }
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/posts/create`,
          formData,
          { headers: apiHeaders }
        );
        // console.log("blog created:", response);
        
        toast.success("Blog added successfully!");
        formik.resetForm();
        onClose();
        fetchBloglist();
      } catch (error) {
        console.error("Error create blog:", error);
        toast.error("Error create blog:", error);
      }
    },
  });

   const handlefileChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("image", file);
  };

  return (
    <Modal open={open} close={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h2>Add Blog</h2>
          <CloseIcon
            onClick={onClose}
            sx={{ font: "20px", cursor: "pointer" }}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <TextField
          className="w-100"
            id="Title"
            label="Title"
            variant="standard"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
          />
          <Editor
            className="mt-2"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
          />
          <TextField
          className="w-100"
            id="meta title"
            label="Meta Title"
            variant="standard"
            name="meta_title"
            value={formik.values.meta_title}
            onChange={formik.handleChange}
          />
          <TextField
          className="w-100"
            id="meta description"
            label="Meta Description"
            variant="standard"
            name="meta_description"
            value={formik.values.meta_description}
            onChange={formik.handleChange}
          />
          <div className="mt-3">
            <input type="file" name="image" onChange={handlefileChange} />
          </div>
          <div className="d-flex justify-content-end">
          <button className="btn btn-secondary mt-3 me-2" onClick={onClose}>
            Cancel
            </button>
            <button className="btn btn-primary mt-3" type="submit">
              Save
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

export default Addblog;
