import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AuctionState.css";
import { RingLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const StateAuctions = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [statesOrProvinces, setStatesOrProvinces] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingStates, setLoadingStates] = useState(true);
  const [countrySearch, setCountrySearch] = useState("");
  const [stateSearch, setStateSearch] = useState("");

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/countries`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const fetchedCountries = response.data.data;
        setCountries(fetchedCountries);
        setSelectedCountry(fetchedCountries[0]?.name || null);
        if (fetchedCountries[0]?.hash_id) {
          fetchStatesOrProvinces(fetchedCountries[0]?.hash_id);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setLoadingCountries(false);
      }
    };
    fetchCountries();
  }, []);

  const navigate = useNavigate();

  const fetchStatesOrProvinces = async (countryId) => {
    setLoadingStates(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/states/${countryId}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const fetchedStatesOrProvinces = response.data.data;
      setStatesOrProvinces(fetchedStatesOrProvinces);
    } catch (error) {
      console.error("Error fetching states or provinces:", error);
    } finally {
      setLoadingStates(false);
    }
  };

  const handleToggle = (country) => {
    setSelectedCountry(country.name);
    fetchStatesOrProvinces(country.hash_id);
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(countrySearch.toLowerCase())
  );

  const filteredStatesOrProvinces = statesOrProvinces.filter((stateOrProvince) =>
    stateOrProvince.name.toLowerCase().includes(stateSearch.toLowerCase())
  );

  const handleStateClick = (stateId) => {
    navigate(`/lots?hash_id=${stateId}`);
  };

  return (
    <div className="state-auction">
      <div className="container-fluid">
        <div className="row auction-home">
          <p className="text-start pt-2 px-2">
            HOME
            <span> &gt; </span>
            <span>AUCTIONS BY STATE/PROVINCE</span>
          </p>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-5">
          <h1 className="state-online-auction">
            {selectedCountry === "United States"
              ? "VIEW LIVE AND ONLINE AUCTIONS IN YOUR STATE"
              : selectedCountry === "Canada"
                ? "VIEW LIVE AND ONLINE AUCTIONS IN YOUR PROVINCE"
                : ""}
          </h1>
        </div>
        <hr />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="map-btns">
              <input
                type="text"
                placeholder="Search states..."
                value={stateSearch}
                onChange={(e) => setStateSearch(e.target.value)}
                className="search-bar state-searchbar"
              />
              {loadingCountries ? (
                <div className="spinner-container">
                  <RingLoader color="#007bff" />
                </div>
              ) : (
                filteredCountries.map((country, index) => (
                  <button
                    key={index}
                    className={`toggle-btn  ${selectedCountry === country.name ? "active" : ""
                      }`}
                    onClick={() => handleToggle(country)}>
                    {country?.name}
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container map-container">
        <div className="row pt-4 mb-5 flex ">
          {loadingStates ? (
            <div className="spinner-container">
              <RingLoader color="#007bff" />
            </div>
          ) : (
            filteredStatesOrProvinces.map((item, index) => (
              <div
                className=" states-hover-bg col-md-3 border flex items-center justify-center hover:bg-[#2695ff]"
                key={index}
                onClick={() => handleStateClick(item.hash_id)}
                style={{ cursor: "pointer" }}>
                <p className="state-province-name m-0 py-2 ">
                  {item?.name}
                </p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default StateAuctions;

