import React, { useEffect, useState } from "react";
import { Navbar, Nav, Dropdown, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import "./Header.css";
import { useAuth } from "../../AuthContext";
import { HashLink } from "react-router-hash-link";
import CategoryIcon from "@mui/icons-material/Category";
import EventIcon from "@mui/icons-material/Event";
import BusinessIcon from "@mui/icons-material/Business";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import ChaletIcon from "@mui/icons-material/Chalet";
import Logo from "../../assets/images/AdobeStock_607256595_Preview.jpeg";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const MainHeader = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const username = localStorage.getItem("username");
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleLogout = () => {
    logout();
    navigate("/");
    setMenuOpen(false);
  };

  const handleNavigateToSignIn = () => {
    navigate("/authentication/sign-in");
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".navbar") && menuOpen) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [menuOpen]);

  return (
    <>
      {menuOpen && <div className="backdrop" onClick={toggleMenu}></div>}
      <Navbar expand="lg" className="main-header-one">
        <Container>
          <div aria-controls="basic-navbar-nav" onClick={toggleMenu} className="d-block d-lg-none">
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </div>
          <Link to="/" className="navbar-brand">
            <img src={Logo} alt="logo" loading="lazy" />
          </Link>
          {username ? (
            <></>
          ) : (
            <Nav.Link onClick={handleNavigateToSignIn} className="text-uppercase login-link d-block d-lg-none">
              Login / New Bidder
            </Nav.Link>
          )}
          <Navbar.Collapse id="basic-navbar-nav" className={`justify-content-center ${menuOpen ? "show" : ""}`}>

            <Nav className="align-items-center">
              {username ? (
                <></>
              ) : (
                <Nav.Link onClick={handleNavigateToSignIn} className="text-uppercase login-link d-none d-lg-block">
                  Login / New Bidder
                </Nav.Link>
              )}

              <div className="close-btn d-block d-lg-none">
                <CloseIcon onClick={() => setMenuOpen(false)} />
              </div>
              <Dropdown className="mx-3">
                <Dropdown.Toggle as="div" className="text-uppercase font-[700] text-[#555555]">
                  Find Auctions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/lots" onClick={() => setMenuOpen(false)}>
                    <CategoryIcon /> All Categories
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/lots?status=CLOSING" onClick={() => setMenuOpen(false)}>
                    <EventIcon /> Today’s events
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/companysearch" onClick={() => setMenuOpen(false)}>
                    <BusinessIcon /> Company search
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} smooth to="/#hot-auction" onClick={() => setMenuOpen(false)}>
                    <AssistantPhotoIcon /> Hot auctions
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/stateauctions" onClick={() => setMenuOpen(false)}>
                    <ChaletIcon /> Auction by state
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Nav.Link as={Link} to="/blog" className="text-uppercase" onClick={() => setMenuOpen(false)}>
                Blog
              </Nav.Link>
              <Nav.Link as={Link} to="/home/sellstuff" className="text-uppercase" onClick={() => setMenuOpen(false)}>
                Sell
              </Nav.Link>
              <Nav.Link as={Link} to="/subscribe" className="text-uppercase" onClick={() => setMenuOpen(false)}>
                Join Our Mailing List
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/manage-notification" className="text-uppercase" onClick={() => setMenuOpen(false)}>
                Manage Notifications
              </Nav.Link> */}
            </Nav>
            <Nav className="d-none d-lg-block">
              {username && (
                <Dropdown align="end" className="ms-3">
                  <Dropdown.Toggle as="div" className="avatar-toggle">
                    <Avatar name={username} size="30" round />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item className="text-capitalize">
                      <strong>{username}</strong>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => { navigate("/account/watchlist"); setMenuOpen(false); }}>WatchList</Dropdown.Item>
                    <Dropdown.Item onClick={() => { navigate("/account/profile"); setMenuOpen(false); }}>Account Info</Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
          </Navbar.Collapse>
          {username && (
            <Nav className="d-block d-lg-none">
              <Dropdown align="end" className="ms-3">
                <Dropdown.Toggle as="div" className="avatar-toggle">
                  <Avatar name={username} size="30" round />
                </Dropdown.Toggle>
                <Dropdown.Menu className="login-drop">
                  <Dropdown.Item className="text-capitalize">
                    <strong>{username}</strong>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => { navigate("/account/watchlist"); setMenuOpen(false); }}>WatchList</Dropdown.Item>
                  <Dropdown.Item onClick={() => { navigate("/account/profile"); setMenuOpen(false); }}>Account Info</Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          )}
        </Container>
      </Navbar>
    </>
  );
};

export default MainHeader;
