import React, { useState } from "react";
import "./Style/Auctionstyle.css";
import { useAuth } from "../../../AuthContext";
import { NavLink, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

function AuctineerHeader() {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleNavigateToProfile = () => {
    navigate("/");
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleNavigateToSignIn = () => {
    navigate("/authentication/sign-in");
  };

  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const toggleSidebar = () => {
    // console.log("Sidebar toggle clicked");
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      <div className="sideauction-header">
        <div className="side-bar">
          <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
          </button>

          <div
            className={`auctioner-sidebar p-3 ${
              isSidebarOpen ? "open" : "collapsed"
            }`}
          >
            {/* <div className="sidebar-content p-0 py-5 px-1"> */}
            <div className="d-flex flex-wrap  align-items-center mb-4 mt-4">
              {/* <div className="text-start"> */}
              {username ? (
                <div
                  className="login-bidder text-uppercase p-0 px-2"
                  onClick={() => {
                    handleNavigateToProfile();
                    closeSidebar();
                  }}
                >
                  <Avatar name={username} size="30" round className="avatar" />
                  <p className="font-semibold" style={{wordBreak: "break-word"}}>{username}</p>
                </div>
              ) : (
                <p
                  className="login-bidder text-uppercase pb-3"
                  onClick={handleNavigateToSignIn}
                >
                  <b style={{ color: "black" }}>Login / New Bidder</b>
                </p>
              )}
              {/* </div> */}
            </div>
            <nav className="sidebar-nav">
              <ul className="nav flex-column">
                <li className="nav-item px-2">
                  <NavLink
                    to="/auctioner/dashboard"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    onClick={closeSidebar}
                  >
                    Auctions
                  </NavLink>
                </li>
                <li className="nav-item px-2">
                  <NavLink
                    to="/auctioner/Profile"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    onClick={closeSidebar}
                  >
                    Profile
                  </NavLink>
                </li>
                <li className="nav-item px-2">
                  <NavLink
                    to="/auctioner/company"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    onClick={closeSidebar}
                  >
                    Company
                  </NavLink>
                </li>
                <li className="nav-item px-2" onClick={handleLogout}>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    Logout
                  </NavLink>
                </li>
              </ul>
            </nav>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AuctineerHeader;
