import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header3 = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "active-link" : "";
  };
  const token = localStorage.getItem("access_token");

  return (
    <div className="header-last flex items-center justify-center" style={{ background: "#2695FF" }}>
      <div className="container flex items-center justify-center">
        <div className="row last-header">
          <div className="col-md-12 d-flex flex-md-column flex-md-row ">
            <div className="header-item-list d-flex text-uppercase ml-md-3 categories-list  p-3">
              <p
                className={`header-categories flex items-center justify-center m-0 ${isActive(
                  "/lots"
                )}`}
              >
                <Link
                  to="/lots"
                  className="no-underline font-semibold text-white  hover:no-underline focus:shadow-none active:font-bold visited:font:bold"
                >
                  All categories
                </Link>
              </p>
              <p
                className={`header-categories flex items-center justify-center m-0 ${isActive(
                  "/lots?status=CLOSING"
                )}`}
              >
                <Link
                  to="/lots?status=CLOSING"
                  className="no-underline font-semibold text-white focus:shadow-none hover:no-underline active:font-bold visited:font:bold"
                >
                  Today’s events
                </Link>
              </p>
              <p
                className={`header-categories flex items-center justify-center m-0 ${isActive(
                  "/stateauctions"
                )}`}
              >
                <Link
                  to="/stateauctions"
                  className="no-underline font-semibold text-white  focus:shadow-none hover:no-underline active:font-bold visited:font:bold"
                >
                  Auctions by state
                </Link>
              </p>
              <p
                className={`header-categories flex items-center justify-center m-0 ${isActive(
                  "/companysearch"
                )}`}
              >
                <Link
                  to="/companysearch"
                  className="no-underline font-semibold text-white focus:shadow-none hover:no-underline active:font-bold visited:font:bold"
                >
                  Company search
                </Link>
              </p>
              {token ? (
                <p
                  className={`header-categories flex items-center justify-center m-0 ${isActive(
                    "/account/pastwatchlist"
                  )}`}
                >
                  <Link
                    to="/account/watchlist"
                    className="no-underline font-semibold text-white focus:shadow-none hover:no-underline active:font-bold visited:font:bold"
                  >
                    Watch list / Bids
                  </Link>
                </p>
              ) : (
                <p className="d-none"></p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header3;
