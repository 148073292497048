import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./Auctionnew.css";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import * as Yup from "yup";

function NewAuction({ handleClose, open, fetchAuctions }) {
  const [isSubmitting, setIsSubmitting] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    auctioneer: Yup.string().required("Auctioneer is required"),
    start_date: Yup.string().required("Start Date is required"),
    end_date: Yup.string().required("End Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      auctioneer: "",
      start_date: "",
      end_date: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values, "form values");
      setIsSubmitting(true);
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found in localStorage.");
          return;
        }
        const apiHeaders = {
          Authorization: `Bearer ${accessToken}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}/creates/auctions`, values, {
            headers: apiHeaders,
          })
          .then(() => {
            toast.success("Auction created successfully!");
            setIsSubmitting(false);
            formik.resetForm();
            handleClose();
            fetchAuctions();
          });
      } catch (error) {
        console.error("Error create auction:", error);
        setIsSubmitting(false);
        toast.error("Error create auction:", error);
      }
    },
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-grid p-4 auctionnew-section">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h2>New Auction</h2>
              <button
                type="button"
                class="close text-end"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center mb-3 mt-2">
                <label>Name: </label>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Auction name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <p className="err mb-0 ">{formik.errors.name}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3 mt-2">
                <label>Auctioneer: </label>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Auctioneer name"
                    name="auctioneer"
                    value={formik.values.auctioneer}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.auctioneer && formik.errors.auctioneer && (
                    <p className="err mb-0">{formik.errors.auctioneer}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>Start Date: </label>
                <div className="w-100">
                  <input
                    type="datetime-local"
                    placeholder="Start Date"
                    name="start_date"
                    value={formik.values.start_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.start_date && formik.errors.start_date && (
                    <p className="err mb-0">{formik.errors.start_date}</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <label>End Date: </label>
                <div className="w-100">
                  <input
                    type="datetime-local"
                    placeholder="End Date"
                    name="end_date"
                    value={formik.values.end_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.end_date && formik.errors.end_date && (
                    <p className="err mb-0">{formik.errors.end_date}</p>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {" "}
                  {!isSubmitting ? "Submit" : "Submitting..."}
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default NewAuction;
