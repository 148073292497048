import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function Auctiondetails({ auction, fetchAuctions }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [countryTypes, setCountryTypes] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [stateTypes, setStateTypes] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState();
  const [cityTypes, setCityTypes] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState();
  const [currentImage, setCurrentImage] = useState([]);
  const [biddingTypes, setBiddingTypes] = useState([]);

  // --------country get
  useEffect(() => {
    if (auction) {
      fetch(`${process.env.REACT_APP_API_URL}/countries`)
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data.data)) {
            setCountryTypes(data.data);
            const initialBiddingType = data.data.find(
              (type) => type.name === auction?.country?.name
            );
            if (initialBiddingType) {
              setSelectedCountryId(initialBiddingType.hash_id);
              formik.setFieldValue("country", initialBiddingType.hash_id);
            }
          } else {
            console.error("Expected an array but received:", data);
          }
        })
        .catch((error) => console.error("Error fetching data:", error.message));
    }
  }, [auction]);

  const handleCountryChange = (event) => {
    const selectedId = event.target.value;
    setSelectedCountryId(selectedId);
    // fetchStates(selectedId);
    const selectedType = countryTypes.find(
      (type) => type.hash_id === selectedId
    );
    if (selectedType) {
      // setSelectedCountryType(selectedType.name);
    }
    formik.setFieldValue("country", selectedId);
  };

  /*------------get State-------------*/
  const fetchStates = async (selectedCountryId) => {
    fetch(`${process.env.REACT_APP_API_URL}/states/${selectedCountryId}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setStateTypes(data.data);
          // Initialize selectedCountryId based on initial selected name
          const initialBiddingType = data.data.find(
            (type) => type.name === auction?.state?.name
          );
          if (initialBiddingType) {
            setSelectedStateId(initialBiddingType.hash_id);
            formik.setFieldValue("state", initialBiddingType.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleStateChange = (event) => {
    const selectedId = event.target.value;
    setSelectedStateId(selectedId);
    fetchCity(selectedId);
    const selectedType = countryTypes.find(
      (type) => type.hash_id === selectedId
    );
    if (selectedType) {
      // setSelectedStatetype(selectedType.name);
    }
    formik.setFieldValue("state", selectedId);
  };

  useEffect(() => {
    if (selectedCountryId) {
      fetchStates(selectedCountryId);
    }
  }, [selectedCountryId]);

  /*------------get City-------------*/
  const fetchCity = async (selectedCityId) => {
    fetch(`${process.env.REACT_APP_API_URL}/city/${selectedCityId}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setCityTypes(data.data);
          const initialBiddingType = data.data.find(
            (type) => type.name === auction?.city?.name
          );
          if (initialBiddingType) {
            setSelectedCityId(initialBiddingType.hash_id);
            formik.setFieldValue("city", initialBiddingType.hash_id);
          }
        } else {
          // console.error('Expected an array but received:', data);
        }
        // console.log(data, "data")
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleCityChange = (event) => {
    const selectedId = event.target.value;
    setSelectedCityId(selectedId);
    // fetchStates(selectedId);
    const selectedType = cityTypes.find((type) => type.hash_id === selectedId);
    if (selectedType) {
      // setSelectedStatetype(selectedType.name);
    }
    formik.setFieldValue("city", selectedId);
  };

  useEffect(() => {
    if (selectedStateId) {
      fetchCity(selectedStateId);
    }
  }, [selectedStateId]);

  /*------------get bidding type-------------*/
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/bidding-types`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setBiddingTypes(data.data);
          const initialBiddingType = data.data.find(
            (bid) => bid.name === auction?.bidding_type_id?.name
          );
          if (initialBiddingType) {
            formik.setFieldValue("bidding_type_id", initialBiddingType.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [auction]);

  // --------show auction image
  useEffect(() => {
    if (auction?.auction_images?.length) {
      const imageUrls = auction.auction_images.map(
        (img) => `${process.env.REACT_APP_BASE_IMAGE_URL}/${img.image_path}`
      );
      setCurrentImage(imageUrls);
    }
  }, [auction]);

  // ------file change
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      formik.setFieldValue("auction_images", files);
      const newImages = Array.from(files).map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
          reader.onloadend = () => resolve(reader.result);
        });
      });
      Promise.all(newImages).then((images) => setCurrentImage(images));
    }
  };

  // --------Edit auction details
  const validationSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
    // auctioneer: Yup.string().required("Auctioneer is required"),
    // start_date: Yup.string().required("Start Date is required"),
    // end_date: Yup.string().required("End Date is required"),
    // buyer_premium: Yup.string().required("Buyer Premium is required"),
    // description: Yup.string().required("Description is required"),
    // deposit_amount: Yup.string().required("Deposit Amount is required"),
    zip_code: Yup.string().required("Zip Code is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    // terms_and_conditions: Yup.string().required(
    //   "Terms and Conditions is required"
    // ),
    // min_amount: Yup.string().required("Min Amount is required"),
    // max_amount: Yup.string().required("Max Amount is required"),
    // increment_amount: Yup.string().required("Increment Amount is required"),
    // bidding_notice: Yup.string().required("Bidding Notice is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: auction?.name || "",
      auctioneer: auction?.auctioneer || "",
      start_date: auction?.start_date || "",
      end_date: auction?.end_date || "",
      buyer_premium: auction?.buyer_premium || "",
      description: auction?.description || "",
      deposit_amount: auction?.deposit_amount || "",
      country: auction?.country?.hash_Id || "",
      state: auction?.state?.hash_Id || "",
      city: auction?.city?.hash_Id || "",
      zip_code: auction?.zip_code || "",
      terms_and_conditions: auction?.terms_and_conditions || "",
      auction_images: [],
      min_amount: auction?.bid_increments[0]?.min_amount || "0",
      max_amount: auction?.bid_increments[0]?.max_amount || "0",
      increment_amount: auction?.bid_increments[0]?.increment_amount || "",
      bidding_notice: auction?.bidding_notice || "",
      bidding_type_id: auction?.bidding_type_id?.hash_id || "",
      meta_title: auction?.meta_title || "",
      meta_description: auction?.meta_description || "",
      is_shipping_available:
        auction?.shipping_option?.is_shipping_available || 0,
      pickup_info: auction?.shipping_option?.pickup_info || "",
      status: auction?.status || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values, "form values");
      setIsSubmitting(true);
      try {
        const accessToken = localStorage.getItem("access_token");
        const apiHeaders = {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        };
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/updates/auctions/${id}`,
            values,
            { headers: apiHeaders }
          )
          .then((result) => {
            // console.log(response, "response");
            toast.success("Auction Update successfully!");
            setIsSubmitting(false);
            fetchAuctions();
          });
      } catch (error) {
        console.error("Error update auction:", error);
        setIsSubmitting(false);
        toast.error("Error update auction:", error);
      }
    },
  });

  // useEffect(() => {
  //   if (formik.errors && Object.keys(formik.errors).length > 0) {
  //     console.log("Validation Errors:", formik.errors);
  //   }
  // }, [formik.errors]);

  /*------------Delete Auction-------------*/
  const handleDeleteauction = async () => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          console.error("Access token not found.");
          return;
        }
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/delete/auctions/${id}`,
          { headers }
        );
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        navigate("/auctioner/dashboard");
        fetchAuctions();
        // console.log(`Auction with hashId ${hash_Id} deleted successfully`);
      }
    } catch (error) {
      console.error("Error deleting auction:", error.message);
    }
  };

  return (
    <>
      <section className="detail-section">
        <div className="container">
          <div className="row ">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-danger mb-3"
                onClick={handleDeleteauction}
              >
                <CloseIcon style={{ fontSize: "23px" }} /> Delete Auction
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="row ">
                <div className="col-12 col-md-8 ">
                  <div className="border-1 rounded p-4">
                    <h2 className="mb-3">Auction Information :-</h2>
                    <div className="d-flex align-items-center mb-3">
                      <label>Name: </label>
                      <div className="d-frid w-100">
                        <input
                          type="text"
                          placeholder="Name"
                          name="name"
                          id="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <p className="err mb-0">{formik.errors.name}</p>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mb-3">
                      <label>Auctioneer: </label>
                      <div className="d-grid  w-100">
                        <input
                          type="text"
                          placeholder="Auctioneer"
                          name="auctioneer"
                          id="auctioneer"
                          value={formik.values.auctioneer}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.auctioneer &&
                          formik.errors.auctioneer && (
                            <p className="err mb-0">
                              {formik.errors.auctioneer}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mb-3">
                      <label>Start Date: </label>
                      <div className="d-grid w-100">
                        <input
                          type="datetime-local"
                          placeholder="start_date"
                          name="start_date"
                          id="start_date"
                          value={formik.values.start_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.start_date &&
                          formik.errors.start_date && (
                            <p className="err mb-0">
                              {formik.errors.start_date}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mb-3">
                      <label>End Date: </label>
                      <div className="d-grid w-100">
                        <input
                          type="datetime-local"
                          placeholder="end_date"
                          name="end_date"
                          id="end_date"
                          value={formik.values.end_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.end_date && formik.errors.end_date && (
                          <p className="err mb-0">{formik.errors.end_date}</p>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mb-3">
                      <label>Buyer Premium: </label>
                      <div className="d-grid w-100">
                        <input
                          type="text"
                          placeholder="Buyer premium"
                          name="buyer_premium"
                          id="buyer_premium"
                          value={formik.values.buyer_premium}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.buyer_premium &&
                          formik.errors.buyer_premium && (
                            <p className="err mb-0">
                              {formik.errors.buyer_premium}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mb-3">
                      <label>Deposit Amount: </label>
                      <div className="d-grid w-100">
                        <input
                          type="text"
                          placeholder="Deposit amount"
                          name="deposit_amount"
                          id="deposit_amount"
                          value={formik.values.deposit_amount}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.deposit_amount &&
                          formik.errors.deposit_amount && (
                            <p className="err mb-0">
                              {formik.errors.deposit_amount}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <label>Address: </label>
                      <div className="d-flex align-items-center w-100 gap-3">
                        <div className="d-grid w-100">
                          <select
                            value={selectedCountryId}
                            onChange={handleCountryChange}
                            onBlur={formik.handleBlur}
                            name="country"
                            id="country"
                            className="w-100 border-1 p-3"
                          >
                            {Array.isArray(countryTypes) &&
                              countryTypes.map((type) => (
                                <option key={type.hash_id} value={type.hash_id}>
                                  {type.name}
                                </option>
                              ))}
                          </select>
                          {formik.touched.country && formik.errors.country && (
                            <p className="err mb-0">{formik.errors.country}</p>
                          )}
                        </div>
                        <div className="d-grid w-100">
                          <select
                            value={selectedStateId}
                            onChange={handleStateChange}
                            onBlur={formik.handleBlur}
                            id="state"
                            name="state"
                            className="w-100 border-1 p-3"
                          >
                            {Array.isArray(stateTypes) &&
                              stateTypes.map((type) => (
                                <option key={type.hash_id} value={type.hash_id}>
                                  {type.name}
                                </option>
                              ))}
                          </select>
                          {formik.touched.state && formik.errors.state && (
                            <p className="err mb-0">{formik.errors.state}</p>
                          )}
                        </div>
                        <div className="d-grid w-100">
                          <select
                            value={selectedCityId}
                            onChange={handleCityChange}
                            onBlur={formik.handleBlur}
                            id="city"
                            name="city"
                            className="w-100 border-1 p-3"
                          >
                            {Array.isArray(cityTypes) &&
                              cityTypes.map((city) => (
                                <option key={city.hash_id} value={city.hash_id}>
                                  {city.name}
                                </option>
                              ))}
                          </select>
                          {formik.touched.city && formik.errors.city && (
                            <p className="err mb-0">{formik.errors.city}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mb-3">
                      <label>Zip Code: </label>
                      <div className="d-grid w-100">
                        <input
                          type="text"
                          placeholder="Zip code"
                          name="zip_code"
                          id="zip_code"
                          value={formik.values.zip_code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.zip_code && formik.errors.zip_code && (
                          <p className="err mb-0">{formik.errors.zip_code}</p>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <label>Description: </label>
                      <div className="d-grid w-100">
                        <textarea
                          placeholder="Description"
                          rows={3}
                          name="description"
                          id="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></textarea>
                        {formik.touched.description &&
                          formik.errors.description && (
                            <p className="err mb-0">
                              {formik.errors.description}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <label>Terms and Condition: </label>
                      <div className="d-grid w-100">
                        <textarea
                          placeholder="Terms and Condition"
                          rows={3}
                          name="terms_and_conditions"
                          id="terms_and_conditions"
                          value={formik.values.terms_and_conditions}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></textarea>
                        {formik.touched.terms_and_conditions &&
                          formik.errors.terms_and_conditions && (
                            <p className="err mb-0">
                              {formik.errors.terms_and_conditions}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="border-1 rounded p-4">
                    <h2 className="mb-3">Featured Image :-</h2>
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      className="w-100"
                    >
                      Upload Image
                      <VisuallyHiddenInput
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        id="auction-images-upload"
                        multiple
                        onChange={handleFileChange}
                        name="auction-images-upload"
                      />
                    </Button>
                    {currentImage && currentImage.length > 0 && (
                      <div className="mt-3">
                        {currentImage &&
                          currentImage.map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Preview ${index}`}
                              style={{
                                width: "150px",
                                maxHeight: "150px",
                                objectFit: "contain",
                                marginRight: "10px",
                              }}
                              loading="lazy"
                            />
                          ))}
                      </div>
                    )}
                  </div>
                  <button
                    className="btn btn-primary mt-3"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {!isSubmitting ? "Update" : "Updating..."}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Auctiondetails;
