import { useState, useEffect } from "react";
import axios from "axios";
import "./AuctionSignup.css";
import {
  Card,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const faqData = [
  {
    question: "For Sole Proprietors",
    answer: `  
            <ul>
                <li class="faq-list-item"> Copy of a driver’s license or valid photo ID</li>
                <li class="faq-list-item"> Copies of any business registrations or licensing required by your state, city or county</li>
            </ul>`,
    type: "auctioner",
  },
  {
    question: "For All Other Business Structures",
    answer: `  
            <ul>
                <li class="faq-list-item">A copy of your business registration (e.g. - Articles of Incorporation, Certificate of Organization, etc.)</li>
                <li class="faq-list-item">If applicable, a copy of any professional licensing documents as required to do business in your state, city or county
   </ul>`,
    type: "answer",
  },
  {
    question:
      "Companies Planning to Operate Under a DBA (Doing Business As) or Fictitious Business Name on Auctionslive.net",
    answer: `  
            <ul>
                <li class="faq-list-item">A copy of your Doing Business As (DBA) or Fictitious Business Name filing as required by your state, city or county</li>            
   </ul>`,
    type: "documents",
  },
  {
    question: "Auction Companies and Auctioneers",
    answer: `  
            <ul>
                <li class="faq-list-item">A copy of Auctioneers license or certificate of registration with an Auctioneers Association</li>          
                 <li class="faq-list-item">If you are planning on selling Firearms, a copy of your Federal Firearms License (“FFL”), or an attestation stating you are
exempt because you exclusively sell antique firearms as defined by 18 U.S. Code § 921(16)</li>       
   </ul>`,
    type: "lastquestion",
  },
];

function Cover() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  // const [statesProvienceList, setStatesProvienceList] = useState([]);
  // const [companyCityList, setCompanyCityList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState([]);
  const [business, setBusiness] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [imagePaths, setImagePaths] = useState({
    image_path_one: null,
    image_path_two: null,
    image_path_three: null,
    image_path_four: null,
  });
  const [formValues, setFormValues] = useState({
    company: "",
    under_dba: false,
    parent_company_name: "",
    // phone_number: "",
    // customer_service_phone_number: "",
    // email_address: "",
    // fax_number: "",
    // street: "",
    // country: "",
    // state_province: "",
    // company_city: "",
    business_structure_id: "",
    // zip_postal_code: "",
    website_address: "",
    other_marketplaces: "",
    // representative_first_name: "",
    // representative_middle_initial: "",
    // representative_last_name: "",
    // representative_business_title: "",
    // representative_preferred_phone_number: "",
    // representative_alternate_phone_number: "",
    // representative_preferred_email_address: "",
    first_name: "",
    last_name: "",
    country_id: "",
    city: "",
    state_id: "",
    postal_code: "",
    phone1: "",
    phone2: "",
    // fax: "",
    username: "",
    password: "",
    password_confirmation: "",
    email: "",
    // confirmEmail: "",
    hide_username: false,
    allow_magnifying_glass: false,
    send_email_notifications: false,
    address: "",
    newsletter: false,
    role: "",
  });

  const navigate = useNavigate();

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    fetchRoles();
    fetchCountries();
    businessStructure();
  }, []);

  // useEffect(() => {
  //   if (formValues.country) {
  //     fetchStates(formValues.country, "stateprovience");
  //   } else {
  //     setStates([]);
  //   }
  // }, [formValues.country]);

  // useEffect(() => {
  //   if (formValues.state_province) {
  //     fetchCity(formValues.state_province, "company_city");
  //   } else {
  //     setCity([]);
  //   }
  // }, [formValues.state_province]);

  useEffect(() => {
    if (formValues.country_id) {
      fetchStates(formValues.country_id, "states");
    } else {
      setStates([]);
    }
  }, [formValues.country_id]);

  useEffect(() => {
    if (formValues.state_id) {
      fetchCity(formValues.state_id, "city");
    } else {
      setCity([]);
    }
  }, [formValues.state_id]);

  /*------------get Roles-------------*/
  const fetchRoles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/roles`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const rolesData = response.data.roles;
      setRoles(rolesData || []);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };


  /*------------get contury-------------*/
  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/countries`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const { data } = response.data;
      setCountries(data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };


  /*------------get State-------------*/
  const fetchStates = async (countryId, type) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/states/${countryId}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const { data } = response.data;
      // if (type === "stateprovience") {
      //   setStatesProvienceList(data);
      // }
      if (type === "states") {
        setStates(data);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };


  /*------------get City-------------*/
  const fetchCity = async (cityId, type) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/city/${cityId}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const { data } = response.data;     
      if (type === "city") {
        setCity(data);
      }      
      // console.log(data, "city")
    } catch (error) {
      console.error("Error fetching city:", error);
    }
  };


  const handleFileChange = (e, pathKey) => {
    const file = e.target.files[0];
    if (file) {
      setImagePaths((prevPaths) => ({
        ...prevPaths,
        [pathKey]: file,
      }));
    }
  };

  /*------------get business structures-------------*/
  const businessStructure = async () => {
    try {
     await axios.get(
        `${process.env.REACT_APP_API_URL}/business-structures`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      )
      .then((response) => {
        const { data } = response.data;
        setBusiness(data);
      })
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (formValues.role === 2 && !formValues.company) {
      newErrors.company = "Company Name is required";}    
    if (formValues.role === 2 && !formValues.website_address) {
      newErrors.website_address = "website_address is required";}
    if (!formValues.role) newErrors.role = "Role is required";
    if (!formValues.first_name) newErrors.first_name = "First Name is required";
    if (!formValues.last_name) newErrors.last_name = "Last Name is required";
    if (!formValues.country_id) newErrors.country_id = "Country is required";
    if (!formValues.state_id) newErrors.state_id = "State is required";
    if (!formValues.city) newErrors.city = "City is required";
    if (!formValues.postal_code)
      newErrors.postal_code = "Postal Code is required";
    if (!formValues.phone1) newErrors.phone1 = "Phone 1 is required";
    if (!formValues.username) newErrors.username = "Username is required";
    if (!formValues.password) newErrors.password = "Password is required";
    if (!formValues.password) {
      newErrors.password = "Password is required";
    } else if (formValues.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
    }
    if (formValues.password !== formValues.password_confirmation)
      newErrors.password_confirmation = "Passwords must match";
    if (!formValues.email) newErrors.email = "Email is required";
    if (!formValues.address) newErrors.address = "Address is required";
    return newErrors;
  };

  /*------------Form Submit-------------*/
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const submittingToastId = toast.info("Submitting form...", {
      autoClose: false,
    });
    setSubmitting(true);
    try {
      const formData = new FormData();
      // console.log(formData,"fromdata")
      const updatedFormValues = {
        ...formValues,
        hide_username: formValues.hide_username ? 1 : 0,
        allow_magnifying_glass: formValues.allow_magnifying_glass ? 1 : 0,
        send_email_notifications: formValues.send_email_notifications ? 1 : 0,
        newsletter: formValues.newsletter ? 1 : 0,
        under_dba: formValues.under_dba ? 1 : 0,
      };
      if (imagePaths.image_path_one) {
        formData.append("image_path_one", imagePaths.image_path_one);
      }
      if (imagePaths.image_path_two) {
        formData.append("image_path_two", imagePaths.image_path_two);
      }
      if (imagePaths.image_path_three) {
        formData.append("image_path_three", imagePaths.image_path_three);
      }
      if (imagePaths.image_path_four) {
        formData.append("image_path_four", imagePaths.image_path_four);
      }
      Object.keys(updatedFormValues).forEach((key) => {
        formData.append(key, updatedFormValues[key]);
      });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        formData,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        toast.dismiss(submittingToastId);
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/authentication/sign-in");
        }, 4000);
      })         
    } catch (error) {
      toast.dismiss(submittingToastId);
      if (error.response) {
        const { status, data } = error.response;
        if (status === 422 && data.errors) {
          Object.keys(data.errors).forEach((key) => {
            toast.error(data.errors[key][0]);
          });
        }
        else {
          toast.error(data.error || "An error occurred.");
        }
      } else {
        toast.error("Network error. Please try again.");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ToastContainer />
      <Card sx={{ maxWidth: 700, mx: "auto", mt: 5, mb: 5, p: 3 }}>
        <Box
          sx={{
            mb: 3,
            textAlign: "center",
            backgroundColor: "white",
            color: "black",
            borderRadius: 1,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Register
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              name="role"
              value={formValues.role}
              onChange={handleChange}
              label="Role"
              error={Boolean(errors.role)}
            >
              {roles && roles.length > 0 ? (
                roles.map((role) => (
                  <MenuItem key={role.id} value={role.id} className="capitalize">
                    {role.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No roles available</MenuItem>
              )}
            </Select>
            {errors.role && (
              <Typography variant="caption" color="error">
                {errors.role}
              </Typography>
            )}
          </FormControl>
        </Box>
        <Box component="form" onSubmit={handleSubmit}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mb: 2 }}
            style={{ display: "none" }}
          >
            Current role: {formValues.role}
          </Typography>

          {formValues.role === 2 && (
            <>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Company Name"
                  variant="outlined"
                  name="company"
                  value={formValues.company}
                  onChange={handleChange}
                  error={Boolean(errors.company)}
                  helperText={errors.company}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="business-label">
                    Select the business structure that applies to your company.
                  </InputLabel>
                  <Select
                    labelId="business-label"
                    id="business_structure_id"
                    name="business_structure_id"
                    value={formValues.business_structure_id}
                    onChange={handleChange}
                    label="Select the business structure that applies to your company."
                    error={Boolean(errors.business_structure_id)}
                  >
                    {business.length > 0 ? (
                      business.map((business) => (
                        <MenuItem key={business.id} value={business.id}>
                          {business.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>
                        No business structures available
                      </MenuItem>
                    )}
                  </Select>
                  {errors.business_structure_id && (
                    <Typography variant="caption" color="error">
                      {errors.business_structure_id}
                    </Typography>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="under_dba"
                      checked={formValues.under_dba}
                      onChange={handleChange}
                    />
                  }
                  label="Will the company be operating under a DBA?"
                />
              </Box>
              {formValues.under_dba && (
                <Box sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    label="Parent Company Name"
                    variant="outlined"
                    name="parent_company_name"
                    value={formValues.parent_company_name}
                    onChange={handleChange}
                    error={Boolean(errors.parent_company_name)}
                    helperText={errors.parent_company_name}
                  />
                </Box>
              )}             
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Company Website Address (url)"
                  variant="outlined"
                  name="website_address"
                  value={formValues.website_address}
                  onChange={handleChange}
                  error={Boolean(errors.website_address)}
                  helperText={errors.website_address}
                />
              </Box>           
              <Box sx={{ mb: 2 }}>
                <p className="text-start">
                  <b>
                    Please refer to the section below to understand what
                    documents you need to submit with your application
                  </b>
                </p>
              </Box>
              <Box sx={{ mb: 2 }}>
                {faqData.map((faq, index) => (
                  <div
                    key={index}
                    style={{
                      border: "1px solid #ddd",
                      marginBottom: "10px",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        padding: "15px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => handleToggle(index)}
                    >
                      {faq.question}
                      <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                        {activeIndex === index ? "-" : "+"}
                      </span>
                    </div>
                    {activeIndex === index && (
                      <>
                        <div
                          style={{ padding: "15px", backgroundColor: "#fff" }}
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                        />
                        <div style={{ padding: "15px" }}>
                          {index === 0 && (
                            <input
                              type="file"
                              name="faqFileOne"
                              onChange={(e) => handleFileChange(e, "image_path_one")}
                            />
                          )}
                          {index === 1 && (
                            <input
                              type="file"
                              name="faqFileTwo"
                              onChange={(e) => handleFileChange(e, "image_path_two")}
                            />
                          )}
                          {index === 2 && (
                            <input
                              type="file"
                              name="faqFileThree"
                              onChange={(e) => handleFileChange(e, "image_path_three")}
                            />
                          )}
                          {index === 3 && (
                            <input
                              type="file"
                              name="faqFileFour"
                              onChange={(e) => handleFileChange(e, "image_path_four")}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </Box>
            </>
          )}

          <Box
            sx={{ mb: 2 }}
            className="d-flex justify-content-between align-items-center gap-2"
          >
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              name="first_name"
              value={formValues.first_name}
              onChange={handleChange}
              error={Boolean(errors.first_name)}
              helperText={errors.first_name}
            />
            {/* </Box>
          <Box sx={{ mb: 2 }}> */}
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              name="last_name"
              value={formValues.last_name}
              onChange={handleChange}
              error={Boolean(errors.last_name)}
              helperText={errors.last_name}
            />
          </Box>
          <Box
            sx={{ mb: 2 }}
            className="d-flex justify-content-between align-items-center w-100"
          >
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              name="address"
              value={formValues.address}
              onChange={handleChange}
              error={Boolean(errors.address)}
              helperText={errors.address}
            />          
          </Box>
          <Box
            sx={{ mb: 2 }}
            className="d-flex justify-content-between align-items-center gap-2"
          >
            <FormControl fullWidth variant="outlined">
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="country-label"
                id="country"
                name="country_id"
                value={formValues.country_id}
                onChange={handleChange}
                label="Country"
                error={Boolean(errors.country_id)}
              >
                {countries.map((country) => (
                  <MenuItem key={country.hash_id} value={country.hash_id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.country_id && (
                <Typography variant="caption" color="error">
                  {errors.country_id}
                </Typography>
              )}
            </FormControl>
            {/* </Box>
          <Box sx={{ mb: 2 }}> */}
            <FormControl fullWidth variant="outlined">
              <InputLabel id="state-label">State</InputLabel>
              <Select
                labelId="state-label"
                id="state"
                name="state_id"
                value={formValues.state_id}
                onChange={handleChange}
                label="State"
                error={Boolean(errors.state_id)}
              >
                {states.map((state) => (
                  <MenuItem key={state.hash_id} value={state.hash_id}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.state_id && (
                <Typography variant="caption" color="error">
                  {errors.state_id}
                </Typography>
              )}
            </FormControl>
          </Box>

          <Box
            sx={{ mb: 2 }}
            className="d-flex justify-content-between align-items-center gap-2"
          >
           
            <FormControl fullWidth variant="outlined">
              <InputLabel id="state-label">City</InputLabel>
              <Select
                labelId="city-label"
                id="city"
                name="city"
                value={formValues.city}
                onChange={handleChange}
                label="City"
                error={Boolean(errors.city)}
              >
                {city.map((city) => (
                  <MenuItem key={city.hash_id} value={city.hash_id}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.state_id && (
                <Typography variant="caption" color="error">
                  {errors.state_id}
                </Typography>
              )}
            </FormControl>

            {/* </Box>
          <Box sx={{ mb: 2 }}> */}
            <TextField
              fullWidth
              label="Postal Code"
              variant="outlined"
              name="postal_code"
              value={formValues.postal_code}
              onChange={handleChange}
              error={Boolean(errors.postal_code)}
              helperText={errors.postal_code}
            />
          </Box>
          <Box
            sx={{ mb: 2 }}
            className="d-flex justify-content-between align-items-center gap-2"
          >
            <TextField
              fullWidth
              label="Phone 1"
              variant="outlined"
              name="phone1"
              value={formValues.phone1}
              onChange={handleChange}
              error={Boolean(errors.phone1)}
              helperText={errors.phone1}
            />
            {/* </Box>
          <Box sx={{ mb: 2 }}> */}
            <TextField
              fullWidth
              label="Phone 2"
              variant="outlined"
              name="phone2"
              value={formValues.phone2}
              onChange={handleChange}
              error={Boolean(errors.phone2)}
              helperText={errors.phone2}
            />
          </Box>
          <Box
            sx={{ mb: 2 }}
            className="d-flex justify-content-between align-items-center gap-2"
          >
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              name="username"
              value={formValues.username}
              onChange={handleChange}
              error={Boolean(errors.username)}
              helperText={errors.username}
            />
            {/* </Box>
          <Box sx={{ mb: 2 }} > */}
            <TextField
              fullWidth
              type="email"
              label="Email"
              variant="outlined"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
           
          </Box>
          <Box
            sx={{ mb: 2 }}
            className="d-flex justify-content-between align-items-center gap-2"
          >
            <TextField
              fullWidth
              // type="password"
              label="Password"
              variant="outlined"
              name="password"
              value={formValues.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* </Box>
          <Box sx={{ mb: 2 }}> */}
            <TextField
              fullWidth
              // type="password"
              label="Confirm Password"
              variant="outlined"
              name="password_confirmation"
              value={formValues.password_confirmation}
              onChange={handleChange}
              error={Boolean(errors.password_confirmation)}
              helperText={errors.password_confirmation}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  name="hide_username"
                  checked={formValues.hide_username}
                  onChange={handleChange}
                />
              }
              label="Hide Username"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  name="allow_magnifying_glass"
                  checked={formValues.allow_magnifying_glass}
                  onChange={handleChange}
                />
              }
              label="Allow Magnifying Glass"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  name="send_email_notifications"
                  checked={formValues.send_email_notifications}
                  onChange={handleChange}
                />
              }
              label="Send Notifications"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  name="newsletter"
                  checked={formValues.newsletter}
                  onChange={handleChange}
                />
              }
              label="Subscribe to Newsletter"
            />
          </Box>

          <Box sx={{ mt: 4 }} className="flex items-center justify-center">
            <Button
              variant="contained"
              className="sign-up-auctioner"
              fullWidth
              type="submit"
              disabled={submitting}
            >
              {submitting ? "SignUp..." : "SignUp"}
            </Button>
          </Box>
          <Box sx={{ mt: 3, mb: 1, textAlign: "center" }}>
            <Typography variant="body2" color="textSecondary">
              Already have an account? &nbsp;
              <Typography
                component="a"
                href="/authentication/sign-in"
                variant="body2"
                color="primary"
              >
                Sign In
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
}

export default Cover;
