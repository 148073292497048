import React, { useEffect, useState } from "react";
import axios from "axios";
import { RingLoader } from "react-spinners";
import Woodburn8 from "../../../assets/images/demo.jpg";
import { Link } from "react-router-dom";

const Auctions = () => {
  const [auctions, setAuctions] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAuctions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auctions/status/hot`,
        { headers: { "ngrok-skip-browser-warning": "69420" } }
      );
      setAuctions(response.data.data);
      // console.log(response.data.data,"hotttt");
    } catch (error) {
      console.error("Error fetching auctions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuctions();
  }, []);

  const displayedAuctions = auctions?.slice(0, 8);

  return (
    <div className="home" id="hot-auction">
      <div className="container">
        <div className="row">
          <div className="auctions mt-3 mb-3">
            <p className="title">Auctionslive</p>
            <p className="subtitle"> Live & Online Auctions.</p>
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-md-6 col-sm-6">
            <h4 className="hot-auctions mt-2">Hot auctions</h4>
          </div>
         
        </div>
        <div className="row mt-3">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <RingLoader color="#3498db" loading={loading} size={150} />
            </div>
          ) : displayedAuctions.length > 0 ? (
            displayedAuctions.map((auction, index) => (
              <div className="col-md-3 mb-4" key={index}>
                <Link
                  to={`/auctions/${auction.hash_id}`}
                  className="text-decoration-none"
                >
                  <div className="card woodburn-card">
                    <div className="card-body woodburn1">
                      <div className="woodburn1-img w-full h-[150px]">
                        <img
                          className="object-cover"
                          src={
                            auction?.auction_images &&
                            auction?.auction_images.length > 0
                              ? `${process.env.REACT_APP_BASE_IMAGE_URL}${auction?.auction_images[0]?.image_path}`
                              : Woodburn8
                          }
                          alt={auction?.name || "Auction Image"}
                          loading="lazy"
                        />
                      </div>
                      <p className="card-text text-center mt-3 woodburn-text text-capitalize">
                        {auction?.name}
                      </p>
                      <h6 className="text-center woodburn-text-heading">
                        Ends: {new Date(auction?.end_date).toLocaleDateString()}
                      </h6>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <p>No auctions available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Auctions;
