import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import "./Notification.css";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";

function Notification() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const url = window.location.href;


  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.auctionslive.net/api/subscribe",
        {
          email: email,
        }
      );     
        toast.success("Subscription successful! ");
        setEmail("");
        setIsSubmitting(false);     
    } catch (err) {
      console.error(err);
      toast.error("Subscription failed. Please try again later.");
      setIsSubmitting(false);
    }
  };
  return (
    <>
     <Helmet>
        <title>Subscribe for Auction Notifications  - Auctionslive</title>
        <meta name="description" content="Stay up-to-date with our weekly Auction Update emails. Subscribe for notifications to receive the latest auction information directly to your inbox. Don’t miss out – sign up today!" />
        <meta property="og:title" content="Subscribe for Auction Notifications  - Auctionslive" />
        <meta property="og:description" content="Stay up-to-date with our weekly Auction Update emails. Subscribe for notifications to receive the latest auction information directly to your inbox. Don’t miss out – sign up today!" />
        <meta property="og:url" content={url} />
        <link rel="canonical" href={url} />
      </Helmet>
      <ToastContainer/>
      <section className="notification-section">
        <div className="container">
          <div className="row">            
              <section className="subscribe mt-4">
                <div className="container">
                  <h1 style={{ fontSize: "26px"}}>Subscribe for Notifications</h1>
                  <p>
                    To receive our weekly Auction Update emails, please complete
                    the form below and click "Subscribe." Thank you for
                    your interest in our auctions!
                  </p>

                  <Form onSubmit={handleSubmit} className="mt-5 mb-4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className="subscribe-btn ps-5 pe-5"
                      disabled={isSubmitting}
                    >
                     {isSubmitting ? "Subscribe..." : "Subscribe"} 
                    </Button>
                  </Form>
                </div>
              </section>           
          </div>
        </div>
      </section>
    </>
  );
}

export default Notification;
