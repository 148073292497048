import React from "react";
import { Table, Button, Spinner } from "react-bootstrap";

const CountryTable = ({
  countries,
  onCountryClick,
  onToggleCountry,
  loading,
}) => (
  <>
    {loading ? (
      <div className="text-center my-5">
        <Spinner animation="border" variant="primary" />
        <p>Loading...</p>
      </div>
    ) : (
      <Table striped bordered hover responsive className="country-table">
        <thead>
          <tr>
            <th>Country Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {countries.length > 0 ? (
            countries.map((country) => (
              <tr
                key={country.hash_id}
                onClick={() => onCountryClick(country.hash_id)}
              >
                <td className="country-list-name">{country?.name}</td>
                <td className="country-list-name">
                  <Button                 
                    variant={country.is_active === 1 ? "success" : "danger"}
                    onClick={(e) => onToggleCountry(country.hash_id, e)}
                  >
                    {country.is_active === 1 ? "Active" : "Inactive"}
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="text-center">
                No countries found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    )}
  </>
);

export default CountryTable;
