import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import axios from "axios";
import "./Table.css";
import Auctiondetails from "./Auctiondetails/Auctiondetails";
import Auctionsetting from "./Auctiondetails/Auctionsetting";
import Lotsdetails from "./Auctiondetails/Lotsdetails/Lotsdetails";

function Tables() {
  const { id } = useParams();
  const [view, setView] = useState("detail");
  const [auction, setAuction] = useState(null);

  const fetchAuctions = useCallback(async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auctions/details/${id}`,
        { headers }
      );
      if (response.data.auction) {
        setAuction(response.data.auction);
      } else {
        console.warn("No auction found for the given ID.");
      }
    } catch (error) {
      console.error("Error fetching auction details:", error.message || error);
    }
  }, [id]);

  useEffect(() => {
    fetchAuctions();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <Box pt={1} pb={2}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <Box
                    mx={2}
                    py={3}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info">
                    <Typography className="tables-title-auctioner">
                      Auction Detail
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(event, newView) => {
                      if (newView !== null) {
                        setView(newView);
                      }
                    }}
                    aria-label="View Toggle"
                    sx={{ mb: 2 }}
                    className="info-btn">
                    <ToggleButton value="detail">Details</ToggleButton>
                    <ToggleButton value="setting">Upload Setting</ToggleButton>
                    <ToggleButton value="lots">lots</ToggleButton>
                  </ToggleButtonGroup>

                  {view === "detail" && auction && (
                    <>                     
                      <Auctiondetails
                        auction={auction}
                        fetchAuctions={fetchAuctions}
                      />
                    </>
                  )}

                  {view === "setting" && auction && (
                    <>                     
                      <Auctionsetting
                        auction={auction}
                        fetchAuctions={fetchAuctions}
                      />
                    </>
                  )}

                  {view === "lots" && auction && (
                    <>                     
                      <Lotsdetails fetchAuctions={fetchAuctions} />
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>      
    </>
  );
}
export default Tables;
