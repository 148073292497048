import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";

const ContactusForm = () => {
  const url = window.location.href;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object({
    isAuctioneer: Yup.string().required(
      "Please select if you are an auctioneer"
    ),
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    zip: Yup.string().required("Zip Code is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      isAuctioneer: "",
      name: "",
      phone: "",
      email: "",
      zip: "",
      description: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setIsSubmitting(true);
      // console.log(values);
      axios
        .post(`${process.env.REACT_APP_API_URL}/seller-send-email`, values, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          toast.success("Form submitted successfully", {
            onClose: () => {
              formik.resetForm();
            },
          });
          setIsSubmitting(false);
        })
        .catch((err) => {
          console.error("Error fetching :", err);
          toast.error("Error fetching :", err);
          setIsSubmitting(false);
        });
    },
  });

  return (
    <>
      <Helmet>
        <title>
          Sell on Auctions | Connect with an Auctioneer - Auctionslive
        </title>
        <meta
          name="description"
          content="Looking to sell items through auctions? Connect with a professional auctioneer to consign your items. Fill out the form to get started, and learn more about seller fees."
        />
        <meta
          property="og:title"
          content="Sell on Auctions | Connect with an Auctioneer - Auctionslive"
        />
        <meta
          property="og:description"
          content="Looking to sell items through auctions? Connect with a professional auctioneer to consign your items. Fill out the form to get started, and learn more about seller fees."
        />
        <meta property="og:url" content={url} />
        <link rel="canonical" href={url} />
      </Helmet>
      <ToastContainer/>
      <div className="form-contact container pt-3">
        <div className="row">
          <div className="text-center pt-3">
            <h1 style={{ fontSize: "26px" }}>Sell on Auctions</h1>
            <p className="pt-3">
              Items to consign? Let us get you in touch with a Auctions
              auctioneer. Please fill out the form below.
            </p>
            <Link to="/seller-fees" className="btn btn-primary">
              Seller Fees
            </Link>
          </div>
          <div className="col-md-2"></div>
          <div
            className="col-md-8 p-4 rounded shadow-sm mt-5 mb-5"
            style={{ background: "#f5f5f5" }}
          >
            <form onSubmit={formik.handleSubmit} className="text-start">
              <div className="mb-3">
                <label className="form-label">Are You An Auctioneer? </label>
                <div>
                  <label>
                    <input
                      className="me-2"
                      type="radio"
                      name="isAuctioneer"
                      value="yes"
                      checked={formik.values.isAuctioneer === "yes"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    Yes
                  </label>
                  <label className="ms-3">
                    <input
                      className="me-2"
                      type="radio"
                      name="isAuctioneer"
                      value="no"
                      checked={formik.values.isAuctioneer === "no"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    No
                  </label>
                  {formik.errors.isAuctioneer && (
                    <div className="text-danger">
                      {formik.errors.isAuctioneer}
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && (
                  <div className="text-danger">{formik.errors.name}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  <FontAwesomeIcon icon={faPhone} className="me-2" />
                  Phone
                </label>
                <PhoneInput
                  country={"us"}
                  value={formik.values.phone}
                  onChange={(phone) => formik.setFieldValue("phone", phone)}
                  inputClass="form-control"
                  onBlur={formik.handleBlur}
                />
                {formik.errors.phone && (
                  <div className="text-danger">{formik.errors.phone}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && (
                  <div className="text-danger">{formik.errors.email}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  <FontAwesomeIcon icon={faLocationDot} className="me-2" />
                  Zip Code
                </label>
                <input
                  type="text"
                  name="zip"
                  className="form-control"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.zip && (
                  <div className="text-danger">{formik.errors.zip}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  rows="4"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.description && (
                  <div className="text-danger">{formik.errors.description}</div>
                )}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactusForm;
