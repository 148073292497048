import React, { useEffect, useState } from "react";
import "../WatchLists/WatchList.css";
import Sidebar from "../../Auctioner/WatchList/Sidebar/Sidebar";
import MainData from "../../Auctioner/WatchList/MaiData/MainData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const WatchList = () => {
  const [data, setData] = useState([]);
  const [selectedButton, setSelectedButton] = useState("Current Bids");
  const buttons = [
    "Current Bids",
    "Watch List",
    "Top Picks",
    "My Auctions",
    "Past Bids",
    "Past Watch List",
  ];
  const SelectedButtonApi = {
    "Current Bids": `${process.env.REACT_APP_API_URL}/bidding/current-bids`,
    "Watch List": `${process.env.REACT_APP_API_URL}/bidding/watch-list`,
    // 'Watch List': `${process.env.REACT_APP_API_URL}/watchlist?type=pastlist`,
    "Top Picks": `${process.env.REACT_APP_API_URL}/bidding/top-picks`,
    "My Auctions": `${process.env.REACT_APP_API_URL}/bidding/my-auctions`,
    "Past Bids": `${process.env.REACT_APP_API_URL}/bidding/past-bids`,
    "Past Watch List": `${process.env.REACT_APP_API_URL}/bidding/past-watch-list`,
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access token not found in localStorage.");
        return;
      }
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      };
      const apiUrl = SelectedButtonApi[selectedButton];
      if (!apiUrl) {
        console.error("Invalid API endpoint.");
        return;
      }
      try {
        const response = await axios.get(apiUrl, { headers });
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [selectedButton]);

  return (
    <div>
      <div className="watchlist-container">
        <div className="header">
          Home &nbsp;
          <FontAwesomeIcon icon={faArrowRight} className="home-arrow" /> &nbsp;
          <span className="selected-button">{selectedButton}</span>
        </div>
        <div className="button-container pt-4">
          {buttons.map((button) => (
            <button
              key={button}
              className={`button ${selectedButton === button ? "active" : ""}`}
              onClick={() => setSelectedButton(button)}
            >
              {button}
            </button>
          ))}
        </div>
        <div className="info-box">
          <div className="info-item">
            <p>Winning "High Bid" Total: $0.00</p>
          </div>
          <div className="info-item">
            <p>Winning "Your Max Bid" Total: $0.00</p>
          </div>
          <div className="button-group">
            <button className="colored-button green">
              0<span className="tooltip">Winning Bids</span>
            </button>
            <button className="colored-button brown">
              0<span className="tooltip">Sealed and Pending Bids</span>
            </button>
            <button className="colored-button red">
              0<span className="tooltip">Losing and Declined Bids</span>
            </button>
            <button className="colored-button blue">
              0<span className="tooltip">Watched Lots</span>
            </button>
            <button className="colored-button print">
              <FontAwesomeIcon icon={faPrint} />
              <span className="tooltip">Print</span>
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row p-0 m-0">
          <div className="col-md-3">
            <Sidebar />
          </div>
          <div className="col-md-9">
            <MainData data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchList;
