import React, { useState } from "react";
import { RingLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faThLarge, faTh, faShippingFast, faBan } from "@fortawesome/free-solid-svg-icons";
import JifImg from "../../../assets/images/Auction.png";
import "../WatchList/MaiData/MainData.css";
import { Link } from "react-router-dom";

const REACT_APP_BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;

const CurrentBids = ({ data = {}, isLoading }) => {
  // console.log(data, "ddddd")
  const [view, setView] = useState("list");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const handleViewChange = (viewType) => {
    setView(viewType);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const isDataArray = Array.isArray(data?.data);
  const dataArray = isDataArray
  ? data.data.filter(item => new Date(item?.lot?.auction?.end_date) <= new Date())
  : [];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = dataArray.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(dataArray.length / itemsPerPage);

  const renderItem = (item) => {
    const hasImage = item?.lot?.images && item?.lot?.images.length > 0;
    const imageSrc = hasImage
      ? `${REACT_APP_BASE_IMAGE_URL}${item.lot.images[0].image_path}`
      : JifImg;
    const lotNumberTitle = `${item?.lot?.title}`;
    const bidAmount = item.amount ? `$${item.amount}` : "Outbid";
    // const winningAmount = item?.status === "Winning" ? `$${item.amount}` : "";
    const shippingStatus = item?.lot?.auction?.shipping_option?.is_shipping_available
      ? <FontAwesomeIcon icon={faShippingFast} />
      : <FontAwesomeIcon icon={faBan} />;

    const daysLeft = (() => {
      const timeLeft = new Date(item?.lot?.auction?.end_date) - new Date();
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      return days > 0 ? `${days} days left` : "Closed";
    })();
    const startDate = `${item?.lot?.auction?.start_date.split(" ")[0]} - ${item?.lot?.auction?.end_date.split(" ")[0]
      }`;
    const status = item.status === "Winning" ? `${item.status}` : "Outbid";
    const hash_id = item?.lot?.hash_id

    const start_date = item?.lot?.auction?.start_date;
    const end_date = item?.lot?.auction?.end_date;

    return (
      <div
        className={`data-item border rounded-xl flex items-center justify-between overflow-hidden w-full ${view === "list" ? "list-view" : ""}`}
      >
      <Link to={`/lots/${hash_id}`} className="text-decoration-none text-black w-100">
        <div className="w-full gap-2 p-3 flex flex-row items-center justify-between watchlist-data">
          <div className="flex items-center justify-center w-1/6">
            <img
              src={imageSrc}
              alt="Lot"
              loading="lazy"
              className="w-full min-h-[149px] min-w-[249px] max-h-[150px] max-w-[250px] object-contain hover:scale-105"
            />
          </div>
          <div className="col-md-3 px-4 flex items-center justify-center w-1/6 watchlist-data-lot">
            <p>{lotNumberTitle}</p>
          </div>
          <div className="col-md-3 flex items-center justify-center flex-col w-1/6 watchlist-data-lot">
            <p className="m-0 text-center">{new Date(start_date).toLocaleDateString()} - {new Date(end_date).toLocaleDateString()}</p>
            <p className="m-0 text-center">
              <span className="font-semibold">Time Left: </span>{daysLeft}
            </p>
          </div>
          <div className="col-md-1 flex items-center justify-center w-1/6 watchlist-data-lot">
            <p className="m-0 ">Amount: <span>{bidAmount}</span></p>
          </div>
          <div className="col-md-1 flex items-center justify-center w-1/6 watchlist-data-lot">
            <p>
              <span style={{ color: status === "Winning" ? "green" : "red" }}>
                {status}
              </span>
            </p>
          </div>
          <div className="col-md-2 flex items-center justify-center w-1/6 watchlist-data-lot">
          <p>Shipping: {shippingStatus}</p>
          </div>
        </div>
        </Link>
      </div>
    );
  };


  const renderGridItem = (item) => {
    const hasImage = item?.lot?.images && item?.lot?.images.length > 0;
    const imageSrc = hasImage
      ? `${REACT_APP_BASE_IMAGE_URL}${item.lot.images[0].image_path}`
      : JifImg;
    const lotNumberTitle = `${item?.lot?.title}`;
    const bidAmount = item.amount ? `$${item.amount}` : "Outbid";
    const winningAmount = item?.status === "Winning" ? `$${item.amount}` : "";
    const shippingStatus = item?.lot?.auction?.shipping_option?.is_shipping_available
      ? <FontAwesomeIcon icon={faShippingFast} />
      : <FontAwesomeIcon icon={faBan} />;

    const daysLeft = (() => {
      const timeLeft = new Date(item?.lot?.auction?.end_date) - new Date();
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      return days > 0 ? `${days} days left` : "Closed";
    })();
    const startDate = `${item?.lot?.auction?.start_date.split(" ")[0]} - ${item?.lot?.auction?.end_date.split(" ")[0]
      }`;
    const status = item.status === "Winning" ? `${item.status}` : "Outbid";
    const hash_id = item?.lot?.hash_id

    const start_date = item?.lot?.auction?.start_date;
    const end_date = item?.lot?.auction?.end_date;

    return (
      <>
        <div className="col-md-3 flex items-center justify-between flex-row gap-3">
        <Link to={`/lots/${hash_id}`} className="text-decoration-none text-black w-100">
          <div className="data-box-grid-2 flex flex-col items-center justify-between">
            <div className="item-header flex items-start justify-start w-full">
              <p className="font-semibold m-0">{lotNumberTitle}</p>
            </div>
            <div className=" w-full h-full flex items-center justify-center">
              <img
                src={imageSrc}
                alt="Lot"
                loading="lazy"
                className="w-full min-h-[149px] min-w-[249px] max-h-[150px] max-w-[250px] object-contain hover:scale-105"
              />
            </div>
            <div className="item-details flex items-center justify-center flex-col">
            <p>{new Date(start_date).toLocaleDateString()} - {new Date(end_date).toLocaleDateString()}</p>
              <p>
                {" "}
                <span className="font-semibold">Time Left : </span>
                {daysLeft}
              </p>
              <p>
                {" "}
                <span className="font-semibold">Amount: : </span>
                {bidAmount}
              </p>
              <p>
              <span style={{ color: status === "Winning" ? "green" : "red" }}>
                {status}
              </span>
            </p>
            <p>Shipping: {shippingStatus}</p>
            </div>
          </div>
          </Link>
        </div>
      </>
    );
  };
  const renderGrid12Item = (item) => {
    const hasImage = item?.lot?.images && item?.lot?.images.length > 0;
    const imageSrc = hasImage
      ? `${REACT_APP_BASE_IMAGE_URL}${item.lot.images[0].image_path}`
      : JifImg;
    const lotNumberTitle = `${item?.lot?.title}`;
    const bidAmount = item.amount ? `$${item.amount}` : "Outbid";
    const winningAmount = item?.status === "Winning" ? `$${item.amount}` : "";
    const shippingStatus = item?.lot?.auction?.shipping_option?.is_shipping_available
      ? <FontAwesomeIcon icon={faShippingFast} />
      : <FontAwesomeIcon icon={faBan} />;

    const daysLeft = (() => {
      const timeLeft = new Date(item?.lot?.auction?.end_date) - new Date();
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      return days > 0 ? `${days} days left` : "Closed";
    })();
    const startDate = `${item?.lot?.auction?.start_date.split(" ")[0]} - ${item?.lot?.auction?.end_date.split(" ")[0]
      }`;
    const status = item.status === "Winning" ? `${item.status}` : "Outbid";
    const address = `${item?.lot?.auction?.city} | ${item?.lot?.auction?.location}`;
    const hash_id = item?.lot?.hash_id

    const start_date = item?.lot?.auction?.start_date;
    const end_date = item?.lot?.auction?.end_date;

    return (
      <>
        <div className="data-box-grid-12 mb-3 border rounded-xl flex items-center justify-between w-full flex-col overflow-hidden">
        <Link to={`/lots/${hash_id}`} className="text-decoration-none text-black w-100">
          <div className="flex items-center justify-between w-full bg-gray-200 py-2 px-3 rounded-t-xl watch-list-single-view-data">
            <div className="item-header flex items-center justify-center ">
              <p className="font-semibold m-0">{lotNumberTitle}</p>
            </div>

            <p className="pt-3 text-center font-semibold">
              <p>
                <span style={{ color: status === "Winning" ? "green" : "red" }}>
                  {status}
                </span>
              </p>
            </p>
          </div>
          <div className="flex items-center justify-between gap-5 px-3 py-2 w-full watch-list-single-view-images">
            <div className="detail-column w-full h-full flex items-center justify-center w-1/5">
              <img
                src={imageSrc}
                alt="Lot"
                loading="lazy"
                className="w-full min-h-[250px] min-w-[250px] max-h-[150px] max-w-[340px] object-contain hover:scale-105"
              />
            </div>
            <div className="flex items-center justify-start gap-3 w-3/5 h-full  ">
              <div className="detail-column flex items-center justify-center flex-col w-3/5  watch-list-single-view-desc">
              <p>{new Date(start_date).toLocaleDateString()} - {new Date(end_date).toLocaleDateString()}</p>
              </div>
              <div className="flex items-center justify-center flex-col w-2/5 h-full watch-list-single-view-desc">
                <div className="detail-column flex items-start justify-center flex-col gap-0">
                <p>
                {" "}
                <span className="font-semibold">Time Left : </span>
                {daysLeft}
              </p>
              <p>
                {" "}
                <span className="font-semibold">Amount: : </span>
                {bidAmount}
              </p>
              
                  
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center flex-col gap-4 w-1/5 px-3 h-full  border-l border-gray-400 watch-list-single-view-desc watch-list-single-address ">
              <p className="m-0 gap-2 flex items-start justify-start flex-col">
                <p className="m-0"> {address}</p>
                <p>Shipping: {shippingStatus}</p>
              </p>
              
            </div>
          </div>
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="top-picks-container watch-list">
          {isLoading ? (
            <div className="loading-spinner">
              <RingLoader color="#000" />
            </div>
          ) : (
            <>
              {dataArray && dataArray.length > 0 ? (
                <>
                  <div className="sort-container">
                    <div className="sort-options flex items-center justify-between w-full mb-4">
                      <div className="flex items-center justify-center gap-2">
                        <button
                          className={`view-button ${view === "list" ? "active" : ""
                            }`}
                          onClick={() => handleViewChange("list")}
                        >
                          <FontAwesomeIcon icon={faList} />
                        </button>
                        <button
                          className={`view-button ${view === "grid-2" ? "active" : ""
                            }`}
                          onClick={() => handleViewChange("grid-2")}
                        >
                          <FontAwesomeIcon icon={faThLarge} />
                        </button>
                        <button
                          className={`view-button ${view === "grid-12" ? "active" : ""
                            }`}
                          onClick={() => handleViewChange("grid-12")}
                        >
                          <FontAwesomeIcon icon={faTh} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="data-container">
                    {view === "list" && dataArray.length > 0 && (
                      <div className="list-view w-full">
                        {currentData.map((item, index) => (
                          <React.Fragment key={index}>
                            {renderItem(item)}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                    {view === "grid-2" && dataArray.length > 0 && (
                      <div className="row pt-3 grid-2 gap-y-4 ">
                        {currentData.map((item, index) => (
                          <React.Fragment key={index}>
                            {renderGridItem(item)}
                          </React.Fragment>
                        ))}
                      </div>
                    )}

                    {view === "grid-12" && dataArray.length > 0 && (
                      <div className="grid-12-view">
                        {currentData.map((item, index) => (
                          <React.Fragment key={index}>
                            {renderGrid12Item(item)}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                    <div className="pagination-controls">
                      <button
                        className="pagination-button"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <i className="fas fa-chevron-left"></i>
                      </button>
                      {Array.from({ length: totalPages }, (_, i) => (
                        <button
                          key={i + 1}
                          className={`pagination-number ${currentPage === i + 1 ? "active" : ""
                            }`}
                          onClick={() => handlePageChange(i + 1)}
                        >
                          {i + 1}
                        </button>
                      ))}
                      <button
                        className="pagination-button"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <i className="fas fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 w-full flex items-center justify-center py-2">
                  <p className="m-0 font-2xl">You haven't placed any bids yet</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentBids;
