import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { RingLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import "./CompanySearchAuction.css";
import Signinmodel from "../Siginmodel/Signinmodel";

const CompanySearchAuction = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [auctions, setAuctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [auctionsPerPage, setAuctionsPerPage] = useState(25);
  const isLoggedIn = !!localStorage.getItem("access_token");
  const [open, setOpen] = useState(false);
  const handleClosed = () => setOpen(false);
  // console.log(auctions, "auction")

  useEffect(() => {
    const checkUserLoggedIn = () => {
      const user = localStorage.getItem("access_token");
      if (user) {
        setUserLoggedIn(true);
      }
    };
    checkUserLoggedIn();

    const fetchAuctions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get/auctions`,
          {
            params: { company: id },
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );

        if (
          response?.data?.status === "error" &&
          response?.data?.message === "No Auction found"
        ) {
          toast.info(response?.data?.message);
        } else if (response?.data?.status === "success") {
          const data = Array.isArray(response?.data?.data)
            ? response?.data?.data
            : [];
          setAuctions(data);
        } else {
          setError("Failed to fetch auctions data");
        }
      } catch (error) {
        if (error?.response && error?.response?.status === 404) {
          toast.error(error?.response?.data?.message);
        } else {
          setError("Error fetching auctions data");
          console.error("Error fetching auctions data:", error?.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAuctions();
  }, [id]);

  const indexOfLastAuction = currentPage * auctionsPerPage;
  const indexOfFirstAuction = indexOfLastAuction - auctionsPerPage;
  const currentAuctions = auctions.slice(
    indexOfFirstAuction,
    indexOfLastAuction
  );
  const totalPages = Math.ceil(auctions.length / auctionsPerPage);

  const handleSelectChange = (e) => {
    setAuctionsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return (
      <div className="loader-container flex items-center justify-center py-5">
        <RingLoader color="#36d7b7" />
      </div>
    );
  }

  if (error) return <div>{error}</div>;
  const handleItemClick = (hash_id) => {
    navigate(`/auctions/${hash_id}`);
  };

  const handleOpenLoginModal = () => {
    setOpen(true);
  };

  const handleClick = () => {
    const isLoggedIn = !!localStorage.getItem("access_token");
    if (!isLoggedIn) {
      handleOpenLoginModal();
    } else {

    }
  };

  return (
    <>
      <div>
        <ToastContainer />
        <div className="container-fluid">
          <div className="row auction-home">
            <p className="text-start pt-2 px-2">
              HOME
              <span> &gt; </span>
              <span>Auction Lots</span>
            </p>
          </div>
        </div>
        <div className="container">
          <div className="px-5 pt-3">
            <div className="mt-3 information-contact">
              <h2 className="px-3 mt-3">Contact Information</h2>
              {auctions.length > 0 ? (
                <>
                  <p className="px-3">
                    <strong>Company:</strong>{" "}
                    {auctions[0]?.created_by?.detail?.company || "N/A"}
                  </p>
                  <p className="px-3">
                    <strong>Phone:</strong>{" "}
                    {auctions[0]?.created_by?.detail?.phone1 || "N/A"}
                  </p>
                  <p className="px-3">
                    <strong>Email:</strong>{" "}
                    {auctions[0]?.created_by?.email || "N/A"}
                  </p>
                  <p className="px-3">
                    <strong>Website:</strong>{" "}
                    <a
                      href={
                        auctions[0]?.created_by?.user_detail?.company_detail
                          ?.website_address
                      }
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      {auctions[0]?.created_by?.user_detail?.company_detail
                        ?.website_address || "N/A"}
                    </a>
                  </p>
                </>
              ) : (
                <p className="px-3">No contact information available.</p>
              )}
            </div>
            <div className="d-flex w-full items-center justify-between">
              <div className=" flex items-center justify-center">
                <p className="mt-3 show text-xl font-semibold">Show:</p>
                <div className="dropdown companies flex items-center justify-center">
                  <select
                    className="show-dropdown"
                    value={auctionsPerPage}
                    onChange={handleSelectChange}>
                    <option value="5">5 auctions</option>
                    <option value="10">10 auctions</option>
                    <option value="25">25 auctions</option>
                    <option value="50">50 auctions</option>
                    <option value="100">100 auctions</option>
                  </select>
                </div>
              </div>
            </div>
            <>
              {currentAuctions.map((auction) => (
                <div
                  key={auction.id}
                  className="mt-4 auction-online flex flex-col items-center justify-center gap-3">
                  <h3 className="text-capitalize">{auction?.name}</h3>
                  <div className="row w-full">
                    <div className="col-12 col-md-4">
                      {auction.auction_images.length > 0 && (
                        <img
                          src={`${process.env.REACT_APP_BASE_IMAGE_URL}${auction?.auction_images[0]?.image_path}`}
                          alt={`Auction ${auction?.title}`}
                          className="comapny-auction-image"
                          loading="lazy"
                          style={{
                            height: "250px",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-8">
                      <div className="row w-full">
                        <div className="col-12 col-md-6">
                          <p>
                            <strong>Address: </strong>
                            {auction?.city?.name}, {auction?.state?.name},{" "}
                            {auction?.country?.name}
                          </p>
                          <p>
                            <strong>Date: </strong> {new Date(auction?.start_date).toLocaleDateString()} - {new Date(auction?.end_date).toLocaleDateString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-6">
                          <p>
                            <strong>Bidding: </strong>{" "}
                            {auction?.bidding_type_id?.name}
                          </p>
                          <p>
                            <strong>Shipping: </strong>
                            {auction?.shipping_option?.is_shipping_available
                              ? "Available"
                              : "Not Available"}
                          </p>
                          <p>
                            <strong>Bid: </strong>
                            {new Date(auction?.end_date).setHours(0, 0, 0, 0) >
                              new Date().setHours(0, 0, 0, 0)
                              ? "Open"
                              : "Closed"}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center item-start w-100 gap-3">
                          <button
                            className="btn py-2 btn-secondary w-100"
                            onClick={() => handleItemClick(auction?.hash_id)}
                          >
                            Auction Details
                          </button>
                          {!isLoggedIn && (
                            <button
                              className="btn py-2 btn-primary w-100"
                              onClick={handleClick}>
                              Register Now
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 mx-3 me-3 w-full bidding-notice flex items-center justify-start rounded-md border ">
                    <p className="m-0">
                      <strong>Description: </strong> {auction?.description}
                    </p>
                  </div>
                  <div className="row mt-2 mx-3 me-3 w-full bidding-notice flex items-center justify-start rounded-md border  ">
                    <p className="m-0">
                      <strong>Bidding:</strong> {auction?.bidding_notice}
                    </p>
                  </div>
                </div>
              ))}
              <div className="pagination-controls">
                <button
                  className="btn btn-primary"
                  onClick={handlePrevious}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, index) => (
                  <button
                    key={index + 1}
                    className={`btn ${currentPage === index + 1
                      ? "btn-secondary"
                      : "btn-outline-secondary"
                      }`}
                    onClick={() => handlePageClick(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  className="btn btn-primary"
                  onClick={handleNext}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
      <Signinmodel open={open} handleClose={handleClosed} />
    </>
  );
};

export default CompanySearchAuction;
