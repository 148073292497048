import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "./SuperAdminHeader.css";
import { useAuth } from "../../../AuthContext";

const SuperAdminHeader = () => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleNavigateToProfile = () => {
    navigate("/");
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleNavigateToSignIn = () => {
    navigate("/authentication/sign-in");
  };

  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="h-full side-header">
       <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
      </button>
      <div
        className={`super-admin-sidebar ${isSidebarOpen ? "open" : "collapsed"
          }`}
      >
       
        <div className="sidebar-content flex flex-col gap-3 ">
          <div className="d-flex align-items-center justify-center">
            <div className="ml-2">
              {username ? (
                <p
                  className="log text-uppercase mb-0 text-2xl"
                  onClick={() => {handleNavigateToProfile(); closeSidebar()}}
                >
                  <b className="px-2">{username}</b>
                </p>
              ) : (
                <p
                  className="login-bidder text-uppercase pb-3"
                  onClick={handleNavigateToSignIn}
                >
                  <b style={{ color: "black" }}>Login / New Bidder</b>
                </p>
              )}
            </div>
          </div>

          <nav className="sidebar-nav">
            <ul className="nav flex-column">
              <li className="nav-item px-2">
                <NavLink
                  to="/super/admin/dashboard"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  User List
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/super/admin/country-list"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  Country List
                </NavLink>
              </li>
             
              <li className="nav-item px-2">
                <NavLink
                  to="/super/admin/bidding-types"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  Bidding List
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/super/admin/configuration"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  Configuration
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/super/admin/categories"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  Category
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/super/admin/blog"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  Blog
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/super/admin/pages"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  Pages
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/super/admin/subscribe"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  Subscriber
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/super/admin/sellstuff"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                SellStuff 
                </NavLink>
              </li>
              <li className="nav-item px-2" onClick={handleLogout}>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  Logout
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminHeader;
