import React, { useState, useEffect } from "react";
import axios from "axios";
import { RingLoader } from "react-spinners";

const SideBar = ({ onSearch, onLocationChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("All location");
  const [showClearButton, setShowClearButton] = useState(false);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/countries`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setLocations(response.data.data);
        // console.log(response.data.data, "locations")
      } catch (error) {
        console.error("Error fetching locations:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchLocations();
  }, []);

  const handleSearchClick = () => {
    onSearch(searchTerm);
  };

  const handleLocationChange = (e) => {
    const locationHashId = e.target.value;
    setSelectedLocation(locationHashId);
    onLocationChange(locationHashId);
    setSearchTerm("");
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowClearButton(value !== "");
  };

  const handleClearClick = () => {
    setSearchTerm("");
    setShowClearButton(false);
  };

  return (
    <div className="sidebar">
      <div className="row">
        
        <div className="mt-3 location-dropdown-list">
          <hr />
          <p className="location-text">LOCATION</p>
          {loading ? (
            <div className="spinner-container">
              <RingLoader color="#007bff" loading={loading} size={60} />
            </div>
          ) : (
            <select
              className="location-dropdown form-control"
              value={selectedLocation}
              onChange={handleLocationChange}
            >
              <option value="All location">All location</option>
              {locations.map((location) => (
                <option key={location.hash_id} value={location.name}>
                  {location?.name}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
