import React from "react";
import LotTypeSelector from "../Common/LotTypeSelector";
import LocationSelector from "../Common/LocationSelector";
import ShippingToggle from "../Common/ShippingToggle";
import CategorySelector from "../CategorySelector";

const LotsSideBar = ({ lotSidebarState, updateState }) => {
  return (
    <div className="lots-sidebar">
      <div className="sidebar auction-sidebar">        
        <LotTypeSelector
          selectedLotStatus={lotSidebarState.selectedLotStatus}
          handleLotStatusChange={(status) =>
            updateState("selectedLotStatus", status)
          }
        />       
        <ShippingToggle
          shippingAvailable={lotSidebarState.shippingAvailable}
          setShippingAvailable={(value) =>
            updateState("shippingAvailable", value)
          }
        />
        <LocationSelector
          handleCountryChange={(value) => updateState("selectedCountry", value)}
          handleCountryStateChange={(value) =>
            updateState("selectedCountryState", value)
          }
        />
        <CategorySelector
          handleCategoryChange={(value) =>
            updateState("selectedCategory", value)
          }
        />
      </div>
    </div>
  );
};

export default LotsSideBar;
