import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';

function Sellerdetails() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const columns = [
        {
            name: 'sr_no', label: 'Sr.No', options: {
                customBodyRenderLite: (dataIndex) => {
                    return dataIndex + 1;
                },
                sort: false,
            },
        },
        { name: 'email', label: 'Email' },
        { name: 'created_at', label: 'Subscribed At', options: { sort: true } },
        {
            name: 'Action',
            label: 'Action',
            options: {
                customBodyRender: (value, tableMeta) => {
                    return (
                        <VisibilityIcon onClick={() => handleViewClick(tableMeta.rowIndex)} style={{ cursor: 'pointer', color: 'green' }} />
                    );
                },
            },
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.auctionslive.net/api/sellstuff');
                // console.log(response.data.data, "sellstuff");
                const formattedData = response.data.data.map(item => ({
                    ...item,
                    created_at: format(new Date(item.created_at), 'MMMM dd, yyyy, h:mm a'),
                }));
                setData(formattedData || []);
            } catch (error) {
                console.error('Error fetching sellstuff:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleViewClick = (rowIndex) => {
        const rowData = data[rowIndex];
        setSelectedRow(rowData);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedRow(null);
    };

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        download: false,
        print: false,
    };

    if (loading) {
        return <div></div>;
    }

    return (
        <section className="admin-subscribe">
            <div className="container">
                <div className="row mt-5 px-3">
                    <h2>ALL SellStuff</h2>
                    <MUIDataTable
                        data={data || []}
                        columns={columns}
                        options={options} />
                </div>
            </div>

            {/* Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedRow?.name} Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRow ? (
                        <div className='px-3'>
                            <div className='d-flex justify-content-between align-items-center text-dark'>
                                <p>
                                    <strong>Name:</strong> {selectedRow?.name}
                                </p>
                                <p>
                                    <strong>Email:</strong> {selectedRow?.email}
                                </p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center text-dark'>
                                <p>
                                    <strong>Phone:</strong> {selectedRow?.phone}
                                </p>
                                <p>
                                    <strong>Zipcode:</strong> {selectedRow?.zipcode}
                                </p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center text-dark'>
                                <p>
                                    <strong>Auctioneer:</strong> {selectedRow?.auctioneer}
                                </p>
                            </div>
                            <p className='text-dark'>
                                <strong>Description:</strong> {selectedRow?.description}
                            </p>
                        </div>
                    ) : (
                        <p>No details available</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default Sellerdetails;
