import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

const ContactUs = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const [info, setinfo] = useState();
  const url = window.location.href;

  const fetchinfo = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/info/contact-us`)
    setinfo(response.data.data)
    // console.log(response.data.data, "info")
  }
  useEffect(() => {
    fetchinfo()
  }, [])

  const handleQuestionClick = (index) => {
    setActiveQuestion(index === activeQuestion ? null : index);
    if (index !== 3) {
      setIsFormSubmitted(false);
      setFormErrors({});
    }
  };

  const handleFormClick = (e) => {
    e.stopPropagation();
  };

  const validateForm = (form) => {
    const errors = {};
    if (!form.subject.value) errors.subject = "Subject is required.";
    if (!form.phone.value || !/^\d{10}$/.test(form.phone.value))
      errors.phone = "Phone number must be 10 digits.";
    if (!form.email.value) errors.email = "Email is required.";
    if (!form.firstName.value) errors.firstName = "First Name is required.";
    if (!form.lastName.value) errors.lastName = "Last Name is required.";
    if (!form.message.value) errors.message = "Message is required.";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const errors = validateForm(form);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setIsFormSubmitted(true);
    form.reset();
    setFormErrors({});
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const contact = () => {
    navigate("/home/sellstuff");
  };

  return (
    <>
      <Helmet>
        <title>{`${info?.meta_title || info?.name}`}</title>
        <meta
          name="description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:title"
          content={`${info?.meta_title || info?.name}`}
        />
        <meta
          property="og:description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:url"
          content={url}
        />
        <link
          rel="canonical"
          href={url}
        />
      </Helmet>

      <div className="contact-us container pt-5">
        <div className="row">
          <p dangerouslySetInnerHTML={{ __html: info?.description || "" }} />
          
        </div>
      </div>
    </>
  );
};

export default ContactUs;
