import axios from "axios";
import React, { useEffect, useState } from "react";
import EditCompany from "./EditCompany";
import "./Detail.css";

function Companydetails() {
  const [userInfo, setUserInfo] = useState(null);
  const hash_id = localStorage.getItem("hash_id");
  const token = localStorage.getItem("access_token");
  const [show, setShow] = useState(false);


  /*------------get single user details-------------*/
  const fetchUserInfo = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_API_URL}/auctioneer/users/${hash_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setUserInfo(response.data);
        // console.log(response.data, "all data");
      })
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>
      <section className="company-details mb-5">
        <div className="container">
          <div className="row p-5">
            <div className="edit-btn mb-4">
              {/* <button className="btn btn-primary edited" onClick={handleShow}>
                Edit Details
              </button> */}
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Company Name</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">{userInfo?.detail?.company}</p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Bussiness Structure</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.business_structure?.name}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50 mt-3">
              <div className="w-50">
                <h5 className="text-start">Company Website</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.website_address}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50 mt-3">
              <div className="w-50">
                <h5 className="text-start">License</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.image_path_one ? (
                    <a
                      href={`${process.env.REACT_APP_BASE_IMAGE_URL}/media/user/${userInfo?.image_path_one}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: '#0178FB' }}
                    >
                      {userInfo?.image_path_one}
                    </a>
                  ) : (
                    <span
                      style={{
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                        color: '#0178FB'
                      }}
                    >
                      No document available
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50 mt-3">
              <div className="w-50">
                <h5 className="text-start">Business Registrations</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.image_path_two ? (
                    <a
                      href={`${process.env.REACT_APP_BASE_IMAGE_URL}/media/user/${userInfo?.image_path_two}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: '#0178FB' }}
                    >
                      {userInfo?.image_path_two}
                    </a>
                  ) : (
                    <span
                      style={{
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                        color: '#0178FB'
                      }}
                    >
                      No document available
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50 mt-3">
              <div className="w-50">
                <h5 className="text-start">DBA license</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.image_path_three ? (
                    <a
                      href={`${process.env.REACT_APP_BASE_IMAGE_URL}/media/user/${userInfo?.image_path_three}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: '#0178FB' }}
                    >
                      {userInfo?.image_path_three}
                    </a>
                  ) : (
                    <span
                      style={{
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                        color: '#0178FB'
                      }}
                    >
                      No document available
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50 mt-3">
              <div className="w-50">
                <h5 className="text-start">Auctioneers license</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.image_path_four ? (
                    <a
                      href={`${process.env.REACT_APP_BASE_IMAGE_URL}/media/user/${userInfo?.image_path_four}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: '#0178FB' }}
                    >
                      {userInfo?.image_path_four}
                    </a>
                  ) : (
                    <span
                      style={{
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                        color: '#0178FB'
                      }}
                    >
                      No document available
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EditCompany show={show} handleClose={handleClose} fetchUser={fetchUserInfo} />
    </>
  );
}

export default Companydetails;
