import React from "react";
import { Modal, Button, Card } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./ViewEmail.css";
const ViewEmail = ({ email, show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Email Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {email && (
          <>
            <Card className="mb-3">
              <Card.Body className="">
                <Card.Title>
                  <span className="underline">Name</span> :-
                </Card.Title>
                <Card.Text>{email?.name}</Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>
                  <span className="underline">Subject</span> :-
                </Card.Title>
                <Card.Text>{email?.subject}</Card.Text>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body>
                <Card.Title>
                  <span className="underline">Body</span> :-
                </Card.Title>
                <ReactQuill className="email-body" value={email.body || ""} readOnly theme="bubble" />
              </Card.Body>
            </Card>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewEmail;
