import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import AuctionLogin from "../../Auctioner/AuctionLogin/AuctionLogin"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

function Signinmodel({ handleClose, open }) {


    return (
        <>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className=''>
                            <button type="button" class="close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <AuctionLogin />
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default Signinmodel