import { Grid, TextField } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Swal from "sweetalert2";

function EditCompany({ show, handleClose, fetchUser }) {
  const [userInfo, setUserInfo] = useState(null);
  const [id, setId] = useState(null);

  const [countryTypes, setCountryTypes] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [countrydata, setCountrydata] = useState(null);

  const [stateTypes, setStateTypes] = useState([]);
  const [statedata, setStatedata] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState("");

  const [cityTypes, setCityTypes] = useState([]);
  const [citydata, setCitydata] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState("");

  const hash_id = localStorage.getItem("hash_id");
  const token = localStorage.getItem("access_token");

  const [isSubmitting, setIssubmitting] = useState(false);

  //   console.log(hash_id)

  /*---------------Country fetch-----------------*/
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/countries`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setCountryTypes(data.data);
          const initialCountry = data.data.find(
            (type) => type.name === countrydata.name
          );
          if (initialCountry) {
            setSelectedCountryId(initialCountry.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [countrydata]);

  const handleCountryChange = (event) => {
    console.log(event.target.value, "event country");
    const selectedId = event.target.value;
    setSelectedCountryId(selectedId);
    const selectedCountry = countryTypes.find(
      (type) => type.hash_id === selectedId
    );
    if (selectedCountry) {
    }
    formik.setFieldValue("country", selectedId);
  };

  /* ----------State fetch-----------*/
  const fetchStates = async (selectedCountryId) => {
    fetch(`${process.env.REACT_APP_API_URL}/states/${selectedCountryId}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setStateTypes(data.data);
          const initialBiddingType = data.data.find(
            (type) => type.name === statedata?.name
          );
          if (initialBiddingType) {
            setSelectedStateId(initialBiddingType.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleStateChange = (event) => {
    console.log(event.target.value, "event state");
    const selectedId = event.target.value;
    setSelectedStateId(selectedId);
    const selectedType = stateTypes.find((type) => type.hash_id === selectedId);
    if (selectedType) {
    }
    formik.setFieldValue("state", selectedId);
  };

  useEffect(() => {
    if (selectedCountryId) {
      fetchStates(selectedCountryId);
    }
  }, [selectedCountryId]);

  /*-----------------City--------------*/
  const fetchCity = async (selectedCityId) => {
    fetch(`${process.env.REACT_APP_API_URL}/city/${selectedCityId}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setCityTypes(data.data);
          const initialBiddingType = data.data.find(
            (type) => type.name === citydata?.name
          );
          if (initialBiddingType) {
            setSelectedCityId(initialBiddingType.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleCityChange = (event) => {
    console.log(event.target.value, "event city");
    const selectedId = event.target.value;
    setSelectedCityId(selectedId);
    const selectedType = cityTypes.find((type) => type.hash_id === selectedId);
    if (selectedType) {
    }
    formik.setFieldValue("city", selectedId);
  };

  useEffect(() => {
    if (selectedStateId) {
      fetchCity(selectedStateId);
    }
  }, [selectedStateId]);

  /*------------get single user details-------------*/
  const fetchUserInfo = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/auctioneer/users/${hash_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response.data;
          const formattedData = {
            ...data,
            company: data?.detail?.company || "",
            website_address: data?.company_detail?.website_address || "",
            roles: data.roles[0]?.id,
            hide_username: data.settings?.hide_username || false,
            allow_magnifying_glass:
              data.settings?.allow_magnifying_glass || false,
            send_email_notifications:
              data.settings?.send_email_notifications || false,
            newsletter: data.settings?.newsletter || false,
          };

          setUserInfo(formattedData);
          setId(response.data.company_detail.id);
          setStatedata(response.data.company_state);
          setCountrydata(response.data.company_country);
          setCitydata(response.data.company_city);
          // console.log(response.data.company_country, "country data");
        });
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  /*------------post user details-------------*/
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company: userInfo?.company || "",
      phone_number: userInfo?.phone_number || "",
      customer_service_phone_number:
        userInfo?.customer_service_phone_number || "",
      country: selectedCountryId || "",
      state_province: selectedStateId || "",
      email_address: userInfo?.email || "",
      fax_number: userInfo?.fax_number || "",
      street: userInfo?.street || "",
      city: selectedCityId || "",
      zip_postal_code: userInfo?.zip_postal_code || "",
      website_address: userInfo?.website_address || "",
    },
    onSubmit: async (values) => {
      // console.log(values);
      setIssubmitting(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/auctioneer/users/company/${id}`,
          values,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        Swal.fire("Success", "Form Submitted Successfully!", "success");
        handleClose();
        setIssubmitting(false);
        fetchUser();
      } catch (error) {
        console.error("Error submitting form:", error);
        Swal.fire("Error submitting form", error.response.data.message);
        setIssubmitting(false);
      }
    },
  });

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <Modal show={show} onHide={handleClose} className="mt-3 edit-company">
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} className="p-4 pt-0">
          <h5 className="pb-3">Edit Company Details</h5>
          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Grid item className="w-50">
              <TextField
                fullWidth
                label="Phone Number"
                type="text"
                name="phone_number"
                placeholder="Phone Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone_number}
                isInvalid={
                  formik.touched.phone_number && !!formik.errors.phone_number
                }
              />
            </Grid>
            <Grid item className="w-50">
              <TextField
                fullWidth
                label="Customer Service Phone Number"
                type="text"
                name="customer_service_phone_number"
                placeholder="Customer Service Phone Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.customer_service_phone_number}
                isInvalid={
                  formik.touched.customer_service_phone_number &&
                  !!formik.errors.customer_service_phone_number
                }
              />
            </Grid>
          </Form.Group>

          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <select
              value={selectedCountryId}
              onChange={handleCountryChange}
              name="country"
              id="country"
              className="w-100 border-1 p-2 text-dark"
            >
              <option value="" disabled>
                country
              </option>
              {Array.isArray(countryTypes) &&
                countryTypes.map((type) => (
                  <option key={type.hash_id} value={type.hash_id}>
                    {type.name}
                  </option>
                ))}
            </select>

            <Form.Control.Feedback type="invalid">
              {formik.errors.country}
            </Form.Control.Feedback>
            <select
              value={selectedStateId}
              onChange={handleStateChange}
              name="state"
              id="state"
              className="w-100 border-1 p-2 text-dark"
            >
              <option value="" disabled>
                state
              </option>
              {Array.isArray(stateTypes) &&
                stateTypes.map((type) => (
                  <option key={type.hash_id} value={type.hash_id}>
                    {type.name}
                  </option>
                ))}
            </select>
          </Form.Group>
          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Grid item className="w-50">
              <select
                value={selectedCityId}
                onChange={handleCityChange}
                name="city"
                id="city"
                className="w-100 border-1 p-2 text-dark"
              >
                <option value="" disabled>
                  city
                </option>
                {Array.isArray(cityTypes) &&
                  cityTypes.map((type) => (
                    <option key={type.hash_id} value={type.hash_id}>
                      {type.name}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item className="w-50">
              <TextField
                fullWidth
                label="Fax Number"
                type="text"
                name="fax_number"
                placeholder="Fax Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fax_number}
                isInvalid={
                  formik.touched.fax_number && !!formik.errors.fax_number
                }
              />
            </Grid>
          </Form.Group>

          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Grid item className="w-50">
              <TextField
                fullWidth
                label="Street"
                type="text"
                name="street"
                placeholder="Street"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.street}
                isInvalid={formik.touched.street && !!formik.errors.street}
              />
            </Grid>
            <Grid item className="w-50">
              <TextField
                fullWidth
                label="Email Address"
                type="text"
                name="email_address"
                placeholder="Email Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email_address}
                isInvalid={
                  formik.touched.email_address && !!formik.errors.email_address
                }
              />
            </Grid>
          </Form.Group>

          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Grid item className="w-50">
              <TextField
                fullWidth
                label="Zip"
                type="text"
                name="zip_postal_code"
                placeholder="Zip"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zip_postal_code}
                isInvalid={
                  formik.touched.zip_postal_code &&
                  !!formik.errors.zip_postal_code
                }
              />
            </Grid>
            <Grid item className="w-50">
              <TextField
                fullWidth
                label="Company Website"
                type="text"
                name="website_address"
                placeholder="Company Website"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.website_address}
                isInvalid={
                  formik.touched.website_address &&
                  !!formik.errors.website_address
                }
              />
            </Grid>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            className="btn me-3"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
          <Button variant="secondary" onClick={handleClose} className="me-2">
            Cancel
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditCompany;
